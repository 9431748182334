import React, { FC, useState } from 'react';
import cn from 'classnames';

import { ProgAIResults } from 'shared/generated-models';
import { Text, TextButton } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	metadata: ProgAIResults['match_metadata'];
}

const ITEMS_TO_SHOW = 7;

type Item = {
	text: string;
	type: 'language' | 'skill';
};

export const CandidateMatched: FC<Props> = ({ metadata }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const progLangs: Item[] =
		metadata.found_filters
			?.find(item => item.filter === 'prog_langs')
			?.values.map(item => {
				return {
					text: item,
					type: 'language'
				};
			}) || [];

	const skills: Item[] =
		metadata.found_skills?.map(skill => {
			return {
				text: skill.skill,
				type: 'skill'
			};
		}) || [];

	const array = progLangs.concat(skills);

	const handleMore = () => {
		setIsOpen(!isOpen);
	};

	if (array.length === 0) return null;

	return (
		<div className={styles.skills}>
			{metadata && metadata.match_score && (
				<Text variant="inter/14/regular" className={styles.match}>
					{`${metadata.match_score} match`}
				</Text>
			)}
			{array.slice(0, ITEMS_TO_SHOW).map(item => (
				<Text
					variant="inter/14/regular"
					color="dark_green"
					className={cn(styles.tag, styles[item.type])}
					key={item.text}>
					{item.text}
				</Text>
			))}
			{isOpen &&
				array.slice(ITEMS_TO_SHOW, array.length).map(item => (
					<Text
						variant="inter/14/regular"
						color="dark_green"
						className={cn(styles.tag, styles[item.type])}
						key={item.text}>
						{item.text}
					</Text>
				))}
			{array.length > ITEMS_TO_SHOW && (
				<TextButton onClick={handleMore} className={styles.open}>
					{isOpen ? 'See less' : `+${array.length - ITEMS_TO_SHOW} more`}
				</TextButton>
			)}
		</div>
	);
};
