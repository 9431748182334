import { ThunkDispatch } from '@reduxjs/toolkit';

import { getTokens, logout } from 'services';
import { environment } from 'environments/environment';
import { setCredentials } from 'store/credentials';

export const refreshTokens = async (dispatch: ThunkDispatch<any, any, any>) => {
	try {
		const tokens = getTokens();
		const options = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				access: tokens.accessToken,
				refresh: tokens.refreshToken
			})
		};
		const refreshedToken = await fetch(`${environment.apiUrl}/auth/refresh/`, options);
		const data = await refreshedToken.json();

		if (data) {
			dispatch(
				setCredentials({
					accessToken: data.access,
					refreshToken: tokens.refreshToken
				})
			);
		} else {
			logout();
		}
	} catch (e) {
		logout();
	}
};
