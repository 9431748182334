import { LoginForm } from 'features/auth';

import styles from './index.module.scss';

export const Login = () => {
	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<LoginForm className={styles.login} />
			</main>
			<section className={styles.image}></section>
		</section>
	);
};
