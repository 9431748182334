import React, { FC, useEffect } from 'react';
import { Modal } from 'antd';

import { CandidateHeader, CandidateProfile } from 'features/candidate';
import { useGetProjectCandidateQuery } from 'services';
import { OpenedContactShort } from 'shared/generated-models';
import { setOpenCandidateModal } from 'widgets/Header';
import { useAppDispatch } from 'store';

import styles from './index.module.scss';

interface Props {
	contact: OpenedContactShort;
	onClose: () => void;
	project_pk: number;
	contacts?: OpenedContactShort[];
	setContact: (contact: OpenedContactShort | null) => void;
	total?: number;
	page: number;
	onChange: (page: number) => void;
}

export const ProjectModal: FC<Props> = ({
	contact,
	onClose,
	project_pk,
	contacts,
	setContact,
	total,
	page,
	onChange
}: Props) => {
	const { data, isFetching } = useGetProjectCandidateQuery({ project_pk, id: contact.id });
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setOpenCandidateModal(true));

		return () => {
			dispatch(setOpenCandidateModal(false));
		};
	}, []);

	return (
		<Modal
			open={true}
			destroyOnClose={true}
			onCancel={onClose}
			footer={null}
			closable={false}
			className={styles.modal}
			transitionName="">
			<CandidateHeader
				variant="projects"
				onClose={onClose}
				data={data}
				contacts={contacts}
				setContact={setContact}
				total={total}
				page={page}
				onChange={onChange}
			/>
			<CandidateProfile data={data} variant="projects" isFetching={isFetching} />
		</Modal>
	);
};
