import React, { FC, useEffect } from 'react';
import { Modal } from 'antd';

import { useAppDispatch } from 'store';
import { CandidateHeader, CandidateProfile } from 'features/candidate';
import { useGetOpenedContactQuery } from 'services';
import { OpenedContactShort } from 'shared/generated-models';
import { setOpenCandidateModal } from 'widgets/Header';

import styles from './index.module.scss';

interface Props {
	contact: OpenedContactShort;
	onClose: () => void;
	contacts?: OpenedContactShort[];
	setContact: (contact: OpenedContactShort | null) => void;
	total?: number;
	page: number;
	onChange: (page: number) => void;
}

export const AcquiredModal: FC<Props> = ({ contact, onClose, contacts, setContact, total, page, onChange }: Props) => {
	const { data, isFetching } = useGetOpenedContactQuery(contact.id);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setOpenCandidateModal(true));

		return () => {
			dispatch(setOpenCandidateModal(false));
		};
	}, []);

	return (
		<Modal
			open={true}
			destroyOnClose={true}
			onCancel={onClose}
			footer={null}
			closable={false}
			className={styles.modal}
			transitionName="">
			<CandidateHeader
				variant="projects"
				onClose={onClose}
				data={data}
				contacts={contacts}
				setContact={setContact}
				total={total}
				page={page}
				onChange={onChange}
			/>
			<CandidateProfile data={data} variant="projects" isFetching={isFetching} />
		</Modal>
	);
};
