import { FC, ReactNode, useState } from 'react';
import cn from 'classnames';

import { Button, Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
	title: string;
	subtitle: ReactNode;
	icon: ReactNode;
}

export const SourceWhaleAccordion: FC<Props> = ({ title, subtitle, icon, className, children }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = () => {
		setIsOpen(prev => !prev);
	};

	return (
		<div className={className}>
			<div className={styles.header} onClick={toggleOpen}>
				<div className={styles.headerLeft}>
					{icon}
					<div>
						<Text variant="inter/15/semi" component="p" className={styles.headerTitle}>
							{title}
						</Text>
						<Text variant="inter/14/regular" color="grey" component="p">
							{subtitle}
						</Text>
					</div>
				</div>
				<Button
					type="ghost"
					htmlType="button"
					className={cn(styles.headerButtonClose, {
						[styles.headerButtonCloseOpened]: isOpen
					})}>
					<Icon icon="chevron" />
				</Button>
			</div>
			<div className={cn(styles.body, { [styles.bodyOpen]: isOpen })}>
				<div>{children}</div>
			</div>
		</div>
	);
};
