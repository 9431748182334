import { useLocation } from 'react-router-dom';
import qs from 'query-string';

export const useFilters = () => {
	const location = useLocation();

	const params = qs.parse(location.search, {
		arrayFormat: 'bracket-separator',
		arrayFormatSeparator: ','
	});

	return {
		...params,
		page: Number(params.page) || 0,
		size: Number(params.size)
	};
};
