import {
	getTodayTimestamp,
	getYesterdayTimestamp,
	getThisWeekTimestamp,
	timestampToTimeFormat,
	timestampToMonthDayFormat
} from 'shared/utils';

type item = {
	time: string;
	link: string;
	text: string;
	hint: string;
};

type section = {
	header: string;
	items: item[];
};

type props = {
	searchHistory: section[];
	itemsMaxLength: number;
	containsDate: boolean;
};

export const useGetSearchHistory = (page: number, size: number): props => {
	const searchHistory = localStorage.getItem('searchHistory');
	if (!searchHistory) return { searchHistory: [], itemsMaxLength: 0, containsDate: false };

	const parsedSearchHistory = JSON.parse(searchHistory).slice((page - 1) * size, page * size);
	const itemsMaxLength = JSON.parse(searchHistory).length;

	const todayItems = [],
		yesterdayItems = [],
		thisWeekItems = [],
		beforeItems = [];
	const today = getTodayTimestamp(),
		yesterday = getYesterdayTimestamp(),
		thisWeekTimestamp = getThisWeekTimestamp();

	for (let i = 0; i < parsedSearchHistory.length; i++) {
		const { timestamp, search, text, hint } = parsedSearchHistory[i];
		const time = timestampToTimeFormat(timestamp);
		if (timestamp > today)
			todayItems.push({
				time,
				link: search,
				text,
				hint
			});
		else if (timestamp > yesterday)
			yesterdayItems.push({
				time,
				link: search,
				text,
				hint
			});
		else if (timestamp > thisWeekTimestamp) {
			const timeWithDate = `${timestampToMonthDayFormat(timestamp)}, ${time}`;
			thisWeekItems.push({
				time: timeWithDate,
				link: search,
				text,
				hint
			});
		} else {
			const timeWithDate = `${timestampToMonthDayFormat(timestamp)}, ${time}`;
			beforeItems.push({
				time: timeWithDate,
				link: search,
				text,
				hint
			});
		}
	}

	const result = [];
	if (todayItems.length > 0) {
		result.push({
			header: 'Today',
			items: todayItems
		});
	}
	if (yesterdayItems.length > 0) {
		result.push({
			header: 'Yesterday',
			items: yesterdayItems
		});
	}
	if (thisWeekItems.length > 0) {
		result.push({
			header: 'Last Week',
			items: thisWeekItems
		});
	}
	if (beforeItems.length > 0) {
		result.push({
			header: 'Previous',
			items: beforeItems
		});
	}
	const containsDate = thisWeekItems.length > 0 || beforeItems.length > 0;

	return { searchHistory: result, itemsMaxLength, containsDate };
};
