import { FC, ReactNode } from 'react';
import { ConfigProvider, Form, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import cn from 'classnames';

import {
	AdvancedTooltip,
	Button,
	Checkbox,
	FormikCheckbox,
	FormikCheckboxGroup,
	FormikTextArea,
	Icon,
	Text
} from 'shared/components/ui';
import { SearchSelect } from 'features/search/components';
import {
	useGetCompaniesQuery,
	useGetCountriesQuery,
	useGetJobTitlesQuery,
	useGetLanguagesQuery,
	useGetLocationsQuery,
	useGetProgrammingLanguagesQuery,
	useGetRegionsQuery,
	useGetRepositoriesQuery,
	useGetSchoolsQuery,
	useGetSimilarSkillsQuery,
	useGetSkillsQuery,
	useGetSubregionsQuery
} from 'services';
import { SeniorityEnum, YoEmploymentEnum, YoExperienceEnum } from 'shared/generated-models';
import { SearchFormParams } from 'features/search/models';
import { AdaptiveDevices, formIsEmpty, getAdaptiveDevice } from 'shared/utils';
import { useIsAdvanced } from 'features/auth';

import styles from './index.module.scss';

interface Props {
	heightClassName?: string;
	header: ReactNode;
	className?: string;
	variant?: 'modal' | 'main';
}

export const SearchForm: FC<Props> = ({ heightClassName, header, className, variant }: Props) => {
	const device = getAdaptiveDevice();
	const isAdvanced = useIsAdvanced();

	const isSmallDevice = device === AdaptiveDevices.MOBILE || device === AdaptiveDevices.TABLET;
	const tooltipPlacement = isSmallDevice ? 'top' : 'right';

	const { values, setValues, validateForm, handleSubmit } = useFormikContext<SearchFormParams>();

	const buttonsDisabled = formIsEmpty({ ...values, size: undefined, page: undefined });

	const handleClearFilters = () => {
		setValues({});
		validateForm({});
	};

	const { data } = useGetSimilarSkillsQuery(
		{
			required_skills: values.required_skills || [],
			bonus_skills: values.bonus_skills || []
		},
		{ skip: !values.required_skills && !values.bonus_skills }
	);

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<Form layout="vertical" className={cn(styles.form, className)}>
				{header}
				<div className={heightClassName}>
					<div className={styles.section}>
						<div className={cn(styles.sectionHeader, styles.sectionHeaderFirst)}>
							<Text variant="inter/15/regular" color="grey">
								Technical Requirements
							</Text>
						</div>
						<div className={styles.formSection}>
							<div style={{ marginBottom: '8px' }}>
								<Text variant="inter/15/medium">Skills</Text>
							</div>
							<FormikTextArea name="text" size="large" placeholder="Enter skills" />
						</div>
						{/* <SearchSelect
							label={
								<div className={styles.label}>
									<Text variant="inter/15/medium">Required skills</Text>
									<Tooltip
										arrow={false}
										title={
											<div className={styles.tooltip}>
												<Text color="dark_green">
													Use multiple skills to&nbsp;describe your perfect candidate. Skills
													are more detailed than programming languages, include technical
													concepts, names of&nbsp;specific technologies, libraries
													or&nbsp;areas of&nbsp;computer science like &lsquo;deep
													learning&rsquo; or &lsquo;computer vision&rsquo;,
													as&nbsp;an&nbsp;example.
												</Text>
											</div>
										}
										placement={tooltipPlacement}
										overlayClassName={styles.labelTooltip}>
										<Icon icon="info" className={styles.labelIcon} />
									</Tooltip>
								</div>
							}
							name="required_skills"
							useQuery={useGetSkillsQuery}
							placeholder="Enter or select Required skills"
							similar={data?.required_skills}
						/> */}
						{/* <SearchSelect
							label={
								<div className={styles.label}>
									<Text variant="inter/15/medium">Bonus skills</Text>
									<Tooltip
										arrow={false}
										title={
											<div className={styles.tooltip}>
												<Text color="dark_green">
													Use multiple skills to&nbsp;describe your perfect candidate. Skills
													are more detailed than programming languages, include technical
													concepts, names of&nbsp;specific technologies, libraries
													or&nbsp;areas of&nbsp;computer science like &lsquo;deep
													learning&rsquo; or &lsquo;computer vision&rsquo;,
													as&nbsp;an&nbsp;example.
												</Text>
											</div>
										}
										placement={tooltipPlacement}
										overlayClassName={styles.labelTooltip}>
										<Icon icon="info" className={styles.labelIcon} />
									</Tooltip>
								</div>
							}
							name="bonus_skills"
							useQuery={useGetSkillsQuery}
							placeholder="Enter or select Bonus skills"
							similar={data?.bonus_skills}
						/> */}
						<SearchSelect
							label={
								<div className={styles.label}>
									<Text variant="inter/15/medium">Programming languages</Text>
									<Tooltip
										arrow={false}
										title={
											<div className={styles.tooltip}>
												<Text color="dark_green">
													Programming languages are Python, C++, JavaScript, etc.
												</Text>
											</div>
										}
										placement={tooltipPlacement}
										overlayClassName={styles.labelTooltip}>
										<Icon icon="info" className={styles.labelIcon} />
									</Tooltip>
								</div>
							}
							name="prog_langs"
							useQuery={useGetProgrammingLanguagesQuery}
							placeholder="Pick programming languages"
						/>
						<SearchSelect
							label="GitHub Repositories"
							name="github_repos"
							useQuery={useGetRepositoriesQuery}
							placeholder="Select GitHub Repositories"
						/>
					</div>
					<div className={styles.section}>
						<div className={styles.sectionHeader}>
							<Text variant="inter/15/regular" color="grey">
								Experience
							</Text>
						</div>
						<div className={styles.formSection}>
							{isAdvanced ? (
								<FormikCheckbox
									label={
										<div className={styles.label}>
											<Text variant="inter/15/medium">Likely to move</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															Show candidates who are more likely to respond to new job
															opportunities
														</Text>
													</div>
												}
												placement={tooltipPlacement}
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}
									name="likely_to_move_prob">
									Likely to move
								</FormikCheckbox>
							) : (
								<FormikCheckbox
									label={
										<div className={styles.label}>
											<Icon icon="lock" size={16} className={styles.labelIcon} />
											<Text variant="inter/15/medium">Likely to move</Text>
											<AdvancedTooltip isAdvanced={isAdvanced}>
												<Icon icon="info" className={styles.labelIcon} />
											</AdvancedTooltip>
										</div>
									}
									disabled={true}
									name="likely_to_move_prob">
									Likely to move
								</FormikCheckbox>
							)}
						</div>
						<SearchSelect
							label="Job title"
							name="job_titles"
							useQuery={useGetJobTitlesQuery}
							placeholder="Enter or select Job title"
						/>
						<div className={styles.formSection}>
							<FormikCheckboxGroup
								label={<Text variant="inter/15/medium">Seniority</Text>}
								name="seniority">
								<Checkbox value={SeniorityEnum.SENIOR}>Senior</Checkbox>
								<Checkbox value={SeniorityEnum.MIDDLE}>Middle</Checkbox>
								<Checkbox value={SeniorityEnum.JUNIOR}>Junior</Checkbox>
								<div className={styles.rockstar}>
									<div className={cn(styles.label, styles.rockstarLabel)}>
										<Text variant="inter/15/medium">Rock Star</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Rock stars are famous experts in their areas, such as Dan
														Abramov or Vitalik Buterin.
													</Text>
												</div>
											}
											placement={tooltipPlacement}
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
									<Checkbox value={SeniorityEnum.ROCK_STAR}>Rock Star</Checkbox>
								</div>
							</FormikCheckboxGroup>
						</div>
						<div className={styles.row}>
							<div className={styles.formSection}>
								<FormikCheckboxGroup
									name="yo_employment"
									label={
										<div className={cn(styles.label, styles.rockstarLabel)}>
											<Text variant="inter/15/medium">Years of employment in software</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															Based on their work experience on Linkedin
														</Text>
													</div>
												}
												placement={tooltipPlacement}
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}>
									<Checkbox value={YoEmploymentEnum._10_}>10+</Checkbox>
									<Checkbox value={YoEmploymentEnum._5_10}>5-10</Checkbox>
									<Checkbox value={YoEmploymentEnum._3_5}>3-5</Checkbox>
									<Checkbox value={YoEmploymentEnum._0_3}>0-3</Checkbox>
								</FormikCheckboxGroup>
							</div>
							<div className={styles.formSection}>
								<FormikCheckboxGroup
									name="yo_experience"
									label={
										<div className={cn(styles.label, styles.rockstarLabel)}>
											<Text variant="inter/15/medium">Years of coding experience</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															Based on their contributions on Github
														</Text>
													</div>
												}
												placement={tooltipPlacement}
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}>
									<Checkbox value={YoExperienceEnum._10_}>10+</Checkbox>
									<Checkbox value={YoExperienceEnum._5_10}>5-10</Checkbox>
									<Checkbox value={YoExperienceEnum._3_5}>3-5</Checkbox>
									<Checkbox value={YoExperienceEnum._0_3}>0-3</Checkbox>
								</FormikCheckboxGroup>
							</div>
						</div>
						<SearchSelect
							label="Companies"
							name="companies"
							useQuery={useGetCompaniesQuery}
							placeholder="Enter Companies"
						/>
						<SearchSelect
							label="Excluded companies"
							name="companies_to_exclude"
							useQuery={useGetCompaniesQuery}
							placeholder="Enter or select Companies to exclude from search"
						/>
					</div>
					<div className={styles.section}>
						<div className={styles.sectionHeader}>
							<Text variant="inter/15/regular" color="grey">
								Location
							</Text>
						</div>
						<div className={styles.row}>
							<SearchSelect
								label="City or state"
								name="locations"
								useQuery={useGetLocationsQuery}
								placeholder="Enter city or state"
							/>
							<SearchSelect
								label="Subregion"
								name="sub_regions"
								useQuery={useGetSubregionsQuery}
								placeholder="Enter Subregion"
							/>
						</div>
						<div className={styles.row}>
							<SearchSelect
								label="Region"
								name="regions"
								useQuery={useGetRegionsQuery}
								placeholder="Enter Region"
							/>
							<SearchSelect
								label="Country"
								name="countries"
								useQuery={useGetCountriesQuery}
								placeholder="Enter Country"
							/>
						</div>
					</div>
					<div className={styles.section}>
						<div className={styles.sectionHeader}>
							<Text variant="inter/15/regular" color="grey">
								Education
							</Text>
						</div>
						<div className={styles.formSection}>
							<FormikCheckbox
								label={
									<div className={styles.label}>
										<Text variant="inter/15/medium">Top Schools</Text>
										<Tooltip
											arrow={false}
											title={
												<div className={styles.tooltip}>
													<Text color="dark_green">
														Find candidates who graduated from top schools in computer
														science
													</Text>
												</div>
											}
											placement={tooltipPlacement}
											overlayClassName={styles.labelTooltip}>
											<Icon icon="info" className={styles.labelIcon} />
										</Tooltip>
									</div>
								}
								name="is_top_school">
								Top schools in computer science
							</FormikCheckbox>
						</div>
						<SearchSelect
							label="Education"
							name="top_schools"
							useQuery={useGetSchoolsQuery}
							placeholder="Enter or select Schools, Universities"
						/>
					</div>
					<div className={styles.section}>
						<div className={styles.sectionHeader}>
							<Text variant="inter/15/regular" color="grey">
								Languages and Diversity
							</Text>
						</div>
						<SearchSelect
							label="Languages"
							name="languages"
							useQuery={useGetLanguagesQuery}
							placeholder="Enter or select Languages"
						/>
						<div className={styles.formSection}>
							<FormikCheckbox
								label={
									<div className={styles.label}>
										<Text variant="inter/15/medium">Diversity filters</Text>
									</div>
								}
								name="is_first_name_female">
								Name contains a female first name
							</FormikCheckbox>
						</div>
					</div>
				</div>
				{variant === 'modal' && (
					<div className={styles.formButtons}>
						<Button className={styles.formButton} disabled={buttonsDisabled} onClick={handleClearFilters}>
							Clear filters
						</Button>
						<Button
							className={styles.formButton}
							type="primary"
							disabled={buttonsDisabled}
							onClick={handleSubmit}>
							Show candidates
						</Button>
					</div>
				)}
			</Form>
		</ConfigProvider>
	);
};
