//TODO: remove this after fix in ENG-101
export const normalizeBrackets = (text?: string): string | undefined => {
	if (!text) return text;
	if (text.length < 2) return text;

	if (text[0] === '[' && text[text.length - 1] === ']') {
		return text.substring(1, text.length - 1);
	}
	return text;
};
