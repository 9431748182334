import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Icon } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
	color?: 'green';
	icon: string;
}

export const Alert: FC<Props> = ({ className, children, color = 'green', icon, ...otherProps }: Props) => {
	return (
		<div
			{...otherProps}
			className={cn(styles.root, className, {
				[styles.green]: color === 'green'
			})}>
			<Icon icon={icon} />
			{children}
		</div>
	);
};
