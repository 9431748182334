import { RegistrationForm } from 'pages';

import styles from './index.module.scss';

export const RegistrationComplete = () => {
	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<RegistrationForm className={styles.login} />
			</main>
			<section className={styles.image}></section>
		</section>
	);
};
