export const getDaysLeft = (date: string | undefined): number => {
	if (!date) return 0;

	const expirationDate = new Date(date);
	const currentDate = new Date();
	const timeDifference = expirationDate.getTime() - currentDate.getTime();

	const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
	return daysLeft;
};

export const getTodayTimestamp = () => {
	const now = new Date();
	now.setHours(0, 0, 0, 0);
	return now.getTime();
};

export const getYesterdayTimestamp = () => {
	const now = new Date();
	now.setTime(now.getTime() - 24 * 60 * 60 * 1000);
	now.setHours(0, 0, 0, 0);
	return now.getTime();
};

export const getThisWeekTimestamp = () => {
	const now = new Date();
	now.setTime(now.getTime() - 7 * 24 * 60 * 60 * 1000);
	now.setHours(0, 0, 0, 0);
	return now.getTime();
};

export function timestampToMonthDayFormat(timestamp: number) {
	const date = new Date(timestamp);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	const month = months[date.getMonth()];
	const day = date.getDate();

	const formattedDate = `${month} ${day}`;
	return formattedDate;
}

export function timestampToTimeFormat(timestamp: number) {
	const date = new Date(timestamp);
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const amOrPm = hours >= 12 ? 'pm' : 'am';

	// Convert to 12-hour time format
	const hours12 = hours % 12 || 12;

	// Add leading zeros to minutes if necessary
	const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

	// Format as HH:MM am/pm
	const formattedTime = `${hours12}:${minutesStr} ${amOrPm}`;
	return formattedTime;
}
