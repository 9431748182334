import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Text } from 'shared/components/ui';
import { PROGAI_EMAIL } from 'shared/utils';

import { useIsFree } from '../../hooks';

import styles from './index.module.scss';

interface Props {
	onClose?: () => void;
}

export const NoCreditsModal: FC<Props> = ({ onClose }: Props) => {
	const navigate = useNavigate();
	const isFree = useIsFree();

	const handleClose = () => {
		onClose?.();
	};

	const handleUpgrade = () => {
		navigate('/registration/plan');
	};

	return (
		<CenterModal onClose={handleClose} icon="credits" iconColor="red" showClose>
			<div>
				<Text variant="inter/20/medium" color="dark_blue" component="h3" className={styles.title}>
					Not enough credits
				</Text>
				<Text color="dark_blue" component="p">
					Contact us to get more credits or edit selection
				</Text>
			</div>
			<ModalFooter>
				<Button component="button" onClick={handleClose} type="ghost">
					Cancel
				</Button>
				{isFree ? (
					<Button type="primary" onClick={handleUpgrade}>
						Upgrade
					</Button>
				) : (
					<Button component="a" href={`mailto:${PROGAI_EMAIL}`} type="primary">
						Contact us
					</Button>
				)}
			</ModalFooter>
		</CenterModal>
	);
};
