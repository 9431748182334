import { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';
import cn from 'classnames';

import { Button, FormikInput, openNotification, Icon, Text } from 'shared/components/ui';
import { emptyUser, logout, useGetCurrentUserQuery, useUpdateUserMutation } from 'services';
import { ProfileEditForm } from 'shared/models';
import { profileFormValidationSchema } from 'features/registration';
import { ProfileSubheader } from 'features/profile';
import { useDebounce } from 'shared/hooks';

import styles from './index.module.scss';

export const ProfileAccount = () => {
	const [isEditing, setIsEditing] = useState(false);
	const [isAutosaveCancelled, setIsAutosaveCancelled] = useState(true);

	const startEditing = () => {
		setIsEditing(true);
		setIsAutosaveCancelled(false);
	};

	const { data } = useGetCurrentUserQuery();
	const initial = data || emptyUser;
	const [updateUser] = useUpdateUserMutation();

	const handleSubmit = async (values: ProfileEditForm) => {
		try {
			await updateUser({
				...initial,
				first_name: values.first_name,
				last_name: values.last_name,
				company: values.company
			});

			openNotification({ text: 'Changes saved!', closable: true });
			setIsEditing(false);
		} catch (e) {
			console.error(e);
		}
	};

	const formik = useFormik<ProfileEditForm>({
		validationSchema: profileFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: true,
		initialValues: { first_name: initial.first_name, last_name: initial.last_name, company: initial.company },
		onSubmit: values => handleSubmit(values)
	});

	const debounceValue = useDebounce(formik.values, 5000, isAutosaveCancelled);

	useEffect(() => {
		if (formik.dirty && formik.isValid) {
			handleSubmit(debounceValue);
		}
	}, [debounceValue]);

	const handleCancel = () => {
		formik.resetForm();
		setIsAutosaveCancelled(true);
		setIsEditing(false);
	};

	return (
		<section>
			<ProfileSubheader>
				<Text variant="inter/16/bold" component="h3">
					Account info
				</Text>
				{isEditing ? (
					<div className={styles.buttons}>
						<Button type="ghost" onClick={handleCancel}>
							Cancel
						</Button>
						<Button type="primary" onClick={formik.handleSubmit} disabled={!formik.isValid}>
							Save changes
						</Button>
					</div>
				) : (
					<Button prefix={<Icon icon="pencil" />} onClick={startEditing}>
						Edit
					</Button>
				)}
			</ProfileSubheader>
			{isEditing ? (
				<FormikProvider value={formik}>
					<Form name="registration" className={styles.form}>
						<div className={styles.formItem}>
							<Text variant="inter/14/medium" color="grey" className={styles.formItemLabel}>
								First name
							</Text>
							<FormikInput name="first_name" size="large" placeholder="Your name" />
						</div>
						<div className={styles.formItem}>
							<Text variant="inter/14/medium" color="grey" className={styles.formItemLabel}>
								Last name
							</Text>
							<FormikInput name="last_name" size="large" placeholder="Your surname" />
						</div>
						<div className={styles.formItem}>
							<Text variant="inter/14/medium" color="grey" className={styles.formItemLabel}>
								Company
							</Text>
							<FormikInput name="company" size="large" placeholder="Your company" />
						</div>
						<div className={styles.formItem}>
							<Text
								variant="inter/14/medium"
								color="grey"
								className={cn(styles.formItemLabel, styles.email)}>
								Gmail account
							</Text>
							<Text className={styles.email}>{initial.email}</Text>
						</div>
					</Form>
				</FormikProvider>
			) : (
				<section className={styles.info}>
					<div className={styles.formItem}>
						<Text variant="inter/14/medium" color="grey" className={styles.formItemLabel}>
							First name
						</Text>
						<Text>{initial.first_name}</Text>
					</div>
					<div className={styles.formItem}>
						<Text variant="inter/14/medium" color="grey" className={styles.formItemLabel}>
							Last name
						</Text>
						<Text>{initial.last_name}</Text>
					</div>
					<div className={styles.formItem}>
						<Text variant="inter/14/medium" color="grey" className={styles.formItemLabel}>
							Company
						</Text>
						<Text>{initial.company}</Text>
					</div>
					<div className={styles.formItem}>
						<Text variant="inter/14/medium" color="grey" className={styles.formItemLabel}>
							Gmail account
						</Text>
						<Text>{initial.email}</Text>
					</div>
				</section>
			)}
			<Button className={styles.logout} onClick={logout}>
				Log out
			</Button>
		</section>
	);
};
