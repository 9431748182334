import React, { Key } from 'react';
import { notification } from 'antd';

import { Text, Icon } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	key?: Key;
	closable?: boolean;
}

interface PropsMultiple {
	key?: Key;
	closable: boolean;
	count: number;
}

export const openNotificationUnlocked = ({ closable, key }: Props) => {
	const handleClose = () => {
		notification.destroy(key);
	};

	notification.open({
		message: (
			<div className={styles.notification}>
				<Text className={styles.notificationText} variant="inter/14/medium" color="white" component="p">
					<Text variant="inter/20/medium">✨</Text> Profile unlocked.
				</Text>
				<div className={styles.row}>
					<div className={styles.rowIcon}>
						<Icon icon="credits" size={24} />
					</div>
					<Text className={styles.rowCredits} variant="inter/14/medium" color="white" component="p">
						1 credit used
					</Text>
					{closable && (
						<button className={styles.notificationButton} onClick={handleClose}>
							<Icon icon="close-big" />
						</button>
					)}
				</div>
			</div>
		),
		key
	});
};

export const openNotificationMultipleUnlocked = ({ key, closable, count }: PropsMultiple) => {
	const handleClose = () => {
		notification.destroy(key);
	};

	notification.open({
		message: (
			<div className={styles.notification}>
				<Text className={styles.notificationText} variant="inter/14/medium" color="white" component="p">
					<Text variant="inter/20/medium">✨</Text> {count} profiles unlocked.
				</Text>
				<div className={styles.row}>
					<div className={styles.rowIcon}>
						<Icon icon="credits" size={24} />
					</div>
					<Text className={styles.rowCredits} variant="inter/14/medium" color="white" component="p">
						{count} credits used
					</Text>
					{closable && (
						<button className={styles.notificationButton} onClick={handleClose}>
							<Icon icon="close-big" />
						</button>
					)}
				</div>
			</div>
		),
		key
	});
};
