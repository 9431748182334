import React, { FC, ReactNode, useState } from 'react';
import cn from 'classnames';

import { Icon, Text, Dropdown } from 'shared/components/ui';
import { CreateProjectModal } from 'features/projects';
import { useGetProjectsShortenQuery } from 'services';
import { TopIdsWithFiltersCandidates, ProjectWithCounters } from 'shared/generated-models';

import styles from './index.module.scss';

interface Props {
	children?: ReactNode;
	candidates: Omit<TopIdsWithFiltersCandidates, 'filters'> & {
		count: number;
	};
	disabled?: boolean;
}

export const ProjectMenu: FC<Props> = ({ children, candidates, disabled }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [project, setProject] = useState<ProjectWithCounters | null>(null);
	const { data } = useGetProjectsShortenQuery();

	const handleNewProject = () => {
		setProject(null);
		setIsModalOpen(true);
		setIsMenuOpen(false);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Dropdown
				disabled={disabled}
				trigger={['click']}
				dropdownRender={() => (
					<div className={styles.dropdown}>
						<button className={styles.button} onClick={handleNewProject}>
							<Icon icon="plus-big" />
							<Text variant="inter/15/semi">New project</Text>
						</button>
						<div className={styles.dropdownList}>
							{data?.map(project => (
								<button
									className={styles.button}
									key={project.id}
									onClick={() => {
										setProject(project);
										setIsModalOpen(true);
										setIsMenuOpen(false);
									}}>
									<Text variant="inter/14/medium" className={styles.text}>
										{project.name}
									</Text>
								</button>
							))}
						</div>
					</div>
				)}
				onOpenChange={setIsMenuOpen}
				open={isMenuOpen}>
				{children}
			</Dropdown>
			{isModalOpen && <CreateProjectModal candidates={candidates} project={project} onClose={closeModal} />}
		</>
	);
};
