import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	title: '',
	isOpenCandidateModal: false, // Нужно ли добавить z-index элементу header (нужно при открытии модалки с кандидатом)
	isOpenPlans: false
};

export const headerSlice = createSlice({
	name: 'header',
	initialState,
	reducers: {
		setHeaderTitle: (state, { payload }) => {
			state.title = payload;
		},
		setOpenCandidateModal: (state, { payload }) => {
			state.isOpenCandidateModal = payload;
		},
		setOpenPlans: (state, { payload }) => {
			state.isOpenPlans = payload;
		}
	}
});

const { reducer } = headerSlice;
export const { setHeaderTitle, setOpenCandidateModal, setOpenPlans } = headerSlice.actions;

export const headerReducer = reducer;
