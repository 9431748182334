import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Pagination, Table, TableSelection, useTableSelection } from 'features/table';
import {
	useGetOpenedContactsQuery,
	useLazyGetOpenedContactsCsvQuery,
	useLazyGetOpenedContactsGoogleSheetsQuery
} from 'services';
import { Button, Icon, SelectProfileTooltip, Text } from 'shared/components/ui';
import { OpenedContactShort } from 'shared/generated-models';
import { AcquiredModal, CandidateRow, ProjectSkeleton } from 'features/projects';
import { CsvPushMenu } from 'features/csv-export';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

const PAGE_SIZE = 20;

export const AcquiredTable = () => {
	const [page, setPage] = useState(0);
	const [contact, setContact] = useState<OpenedContactShort | null>(null);
	const [getCsv] = useLazyGetOpenedContactsCsvQuery();
	const [getGoogleSheetsUrl, { data: sheetUrl }] = useLazyGetOpenedContactsGoogleSheetsQuery();

	const { data, isFetching } = useGetOpenedContactsQuery(page + 1);

	const navigate = useNavigate();

	const ref = useRef<HTMLDivElement>(null);

	const handleContact = (contact: OpenedContactShort) => {
		setContact(contact);
	};

	const closeModal = () => {
		setContact(null);
	};

	useEffect(() => {
		ref.current?.scroll({ top: 0, behavior: 'smooth' });
	}, [page]);

	const {
		selectAll,
		isAllSelected,
		someSelected,
		count,
		selectPage,
		selectCount,
		togglePage,
		isSelected,
		toggle,
		included,
		excluded,
		selectedCount,
		mode
	} = useTableSelection<OpenedContactShort>({
		data: data?.results,
		totalCount: data?.count,
		isFree: true
	});

	useEffect(() => {
		if (sheetUrl?.url) {
			window.open(sheetUrl.url, '_blank');
		}
	}, [sheetUrl]);

	const handleExportCsv = (isFull: boolean) => {
		Analytics.trackCsvExport(count, 'Contacts');
		getCsv({
			ids: included,
			excluded_ids: excluded,
			top: selectedCount || undefined,
			is_full_profiles: isFull,
			filters: {
				page,
				size: PAGE_SIZE
			}
		});
	};

	const handleExportGoogleSheets = (isFull: boolean) => {
		Analytics.trackGoogleSheetsExport(count, 'Contacts');
		getGoogleSheetsUrl({
			ids: included,
			excluded_ids: excluded,
			top: selectedCount || undefined,
			is_full_profiles: isFull,
			filters: {
				page,
				size: PAGE_SIZE
			}
		});
	};

	const handleBack = () => {
		navigate(-1);
	};

	if (isFetching) return <ProjectSkeleton />;

	return (
		<>
			<div className={styles.header}>
				<div className={styles.headerLeft}>
					<Button type="ghost" className={styles.headerButton} onClick={handleBack}>
						<Icon icon="chevron" />
					</Button>
					<Text variant="inter/14/bold" className={styles.headerTitle}>
						All purchased contacts
					</Text>
				</div>
				<SelectProfileTooltip isDisabled={!someSelected}>
					<span>
						<CsvPushMenu
							disabled={!someSelected}
							handleExportCsv={handleExportCsv}
							handleExportGoogleSheets={handleExportGoogleSheets}
							candidates={{
								count,
								ids: included,
								excluded_ids: excluded,
								top: selectedCount || undefined
							}}>
							<Button type="primary" disabled={!someSelected} suffix={<Icon icon="arrow-down" />}>
								Export to
							</Button>
						</CsvPushMenu>
					</span>
				</SelectProfileTooltip>
			</div>
			<Table ref={ref} className={styles.table}>
				<div className={styles.expiration}>
					<Icon icon="info" className={styles.expirationIcon} />
					<Text variant="inter/14/medium">
						Contact information is available for 3 months after the purchase date.
					</Text>
				</div>
				<div className={styles.tableHeader}>
					<div className={styles.controls}>
						<TableSelection
							controls={{
								main: { togglePage, count, isAllSelected },
								extra: { selectPage, selectAll, selectCount }
							}}
							hint="Select"
						/>
						<Pagination page={page} onChange={setPage} total={data?.count || 0} />
					</div>
				</div>

				{data?.results?.map((candidate, idx) => (
					<CandidateRow
						key={candidate.id}
						candidate={candidate}
						variant="acquired"
						onClick={handleContact}
						checkbox={{
							checked: isSelected({ row: candidate, page: page, idx }),
							onChange: () => toggle({ row: candidate, page: page, idx }),
							mode
						}}
					/>
				))}
				<div className={styles.footer}>
					<div className={styles.footerText}>
						<Text variant="inter/13/regular" color="darker_grey">
							End of page
						</Text>
					</div>
					<div className={styles.footerPagination}>
						<Pagination page={page} onChange={setPage} total={data?.count || 0} />
					</div>
				</div>
			</Table>
			{contact && (
				<AcquiredModal
					contact={contact}
					onClose={closeModal}
					contacts={data?.results}
					setContact={setContact}
					total={data?.count || 0}
					page={page}
					onChange={setPage}
				/>
			)}
		</>
	);
};
