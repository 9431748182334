import { Skeleton } from 'antd';

import { SkeletonRow } from 'shared/components/ui';

import styles from './index.module.scss';

export const ProjectSkeleton = () => {
	return (
		<>
			<div className={styles.title}>
				<div className={styles.titleLeft}>
					<SkeletonRow width={16} />
					<SkeletonRow width={153} />
				</div>
				<div className={styles.titleRight}>
					<SkeletonRow width={162} height={38} />
					<SkeletonRow width={114} height={38} />
				</div>
			</div>
			<div className={styles.controls}>
				<div className={styles.controlsLeft}>
					<SkeletonRow className={styles.controlsLeftFirst} width={104} />
					<SkeletonRow width={16} />
					<SkeletonRow width={16} />
				</div>
				<SkeletonRow width={195} />
			</div>
			<div className={styles.header}>
				<SkeletonRow width={74} />
				<SkeletonRow className={styles.headerMiddle} width={74} />
				<SkeletonRow width={74} />
			</div>
			<div className={styles.skeleton}>
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
					<div key={item} className={styles.skeletonRow}>
						<div className={styles.skeletonInfo}>
							<div className={styles.skeletonCheckbox} />
							<Skeleton.Avatar size={60} shape="square" active />
							<div className={styles.skeletonText}>
								<div className={styles.skeletonData}>
									<SkeletonRow width={101} />
									<SkeletonRow width={109} />
								</div>
								<SkeletonRow width={142} />
							</div>
						</div>
						<SkeletonRow width={46} className={styles.skeletonCell} />
						<SkeletonRow width={65} className={styles.skeletonCell} />
						<SkeletonRow width={83} className={styles.skeletonCell} />
					</div>
				))}
			</div>
		</>
	);
};
