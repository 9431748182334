import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';
import { useGetOpenedContactsCountQuery } from 'services';

import styles from './index.module.scss';

export const ProjectsListAcquired = () => {
	const { data } = useGetOpenedContactsCountQuery();

	if (!data || data.count === 0) return null;

	return (
		<NavLink
			to="/projects/acquired"
			className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}>
			<Icon icon="bookmark" className={styles.linkIcon} />
			<div className={styles.linkText}>
				<Text variant="inter/15/medium">All purchased contacts</Text>
				<Text variant="inter/14/medium" color="grey">
					{data?.count}
				</Text>
			</div>
		</NavLink>
	);
};
