import { Link, useNavigate } from 'react-router-dom';

import { Button, Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

export const SharingHeader = () => {
	const navigate = useNavigate();

	const handleLink = () => {
		navigate('/');
	};

	return (
		<header className={styles.header}>
			<Link to="https://getprog.ai" rel="noopener,nofollow,noreferrer">
				<Icon icon="logo" size={40} />
			</Link>
			<Text color="white" variant="grotesk/20/bold" component="h2">
				✨ Source candidates by specific skills on Prog.AI
			</Text>
			<Button type="primary" onClick={handleLink}>
				Join or Sign in
			</Button>
		</header>
	);
};
