import React, { FC, InputHTMLAttributes } from 'react';
import { FieldProps } from 'formik';
import cn from 'classnames';

import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'form'>, FieldProps {}

export const InlineInput: FC<Props> = ({ className, field, form: { errors }, type = 'text', ...otherProps }: Props) => {
	return (
		<div className={cn(styles.wrapper, className)}>
			<input
				name={field.name}
				className={cn(styles.input, { [styles.inputError]: errors[field.name] })}
				value={field.value}
				onChange={field.onChange}
				autoFocus
				{...otherProps}
			/>
			{errors[field.name] && (
				<Text variant="inter/12/regular" className={styles.error}>
					{errors[field.name] as string}
				</Text>
			)}
		</div>
	);
};
