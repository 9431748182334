import { FC, useState } from 'react';

import { CenterModal, ModalFooter } from 'shared/components';
import {
	Button,
	Icon,
	openNotification,
	openNotificationMultipleUnlocked,
	openNotificationUnlocked,
	Text
} from 'shared/components/ui';
import { useGetCurrentUserQuery } from 'services';
import { Analytics } from 'features/analytics';
import { useOpenMultipleContacts } from 'features/candidate';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
	ids: number[];
}

export const MultipleContactsModal: FC<Props> = ({ ids, onClose }: Props) => {
	const [openMultipleContacts] = useOpenMultipleContacts();
	const { data } = useGetCurrentUserQuery();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleGet = async () => {
		if (data && data.credits < ids.length) {
			openNotification({
				text: 'You do not have enough credits',
				closable: true
			});
		} else {
			try {
				setIsLoading(true);
				await openMultipleContacts(ids).unwrap();
				setIsLoading(false);
				if (ids.length === 1) {
					Analytics.trackGetContact(ids[0]!);
					openNotificationUnlocked({ closable: true });
				} else {
					Analytics.trackGetContacts(ids!);
					openNotificationMultipleUnlocked({ closable: true, count: ids.length });
				}
				onClose();
			} catch (e) {
				console.error(e);
			}
		}
	};

	const text =
		ids.length === 1
			? "Getting candidate's contact requires 1 credit"
			: `Getting candidates contacts requires ${ids.length} credits`;

	const unlockText = ids.length === 1 ? 'Unlock profile' : `Unlock profiles`;

	return (
		<CenterModal onClose={onClose} icon="credits" iconColor="green">
			<Text variant="inter/20/medium" color="dark_blue">
				{text}
			</Text>
			<ModalFooter>
				<Button type="ghost" onClick={onClose}>
					Cancel
				</Button>

				{isLoading ? (
					<Button type="primary">
						<Icon icon="loader" size={24} className={styles.spin} />
					</Button>
				) : (
					<Button type="primary" onClick={handleGet}>
						{unlockText}
					</Button>
				)}
			</ModalFooter>
		</CenterModal>
	);
};
