import React, { FC, memo, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { Checkbox, ConfigProvider } from 'antd';
import cn from 'classnames';

import { TableSelectionMode } from 'features/table';
import { Text } from 'shared/components/ui';
import { Project } from 'shared/generated-models';
import { formatDate, isPlural, NUMBERS_FORMAT } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	project: Project;
	checkbox: {
		checked: boolean;
		onChange: () => void;
		mode: TableSelectionMode;
	};
}

export const ProjectRow: FC<Props> = memo(({ project, checkbox }: Props) => {
	const handleClick = (e: MouseEvent) => {
		e.stopPropagation();
	};

	return (
		<NavLink
			to={`/projects/${project.id}`}
			className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}>
			{({ isActive }) => (
				<>
					<ConfigProvider
						theme={{
							components: {
								Checkbox: isActive ? { colorBorder: '#b6cac3' } : {}
							}
						}}>
						<Checkbox
							onClick={handleClick}
							className={styles.checkbox}
							checked={checkbox?.checked}
							onChange={checkbox?.onChange}
						/>
					</ConfigProvider>
					<div>
						<Text variant="inter/15/semi" component="h3" className={styles.title}>
							{project.name}
						</Text>
						<div>
							<Text variant="inter/14/medium" color="grey">
								{isPlural(project.candidates_count)
									? `${project.candidates_count} candidates`
									: `${project.candidates_count} candidate`}
							</Text>
							<Text variant="inter/14/regular" color="grey">
								&nbsp;/ {formatDate(project.created_at, NUMBERS_FORMAT)}
							</Text>
						</div>
					</div>
				</>
			)}
		</NavLink>
	);
}, arePropsEqual);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return (
		oldProps.checkbox.checked === newProps.checkbox.checked &&
		oldProps.checkbox.mode === newProps.checkbox.mode &&
		oldProps.project.candidates_count === newProps.project.candidates_count
	);
}
