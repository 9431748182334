import { FC } from 'react';
import cn from 'classnames';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { useIsFree } from 'features/auth/hooks/use-is-free';
import { Button, Icon, Text } from 'shared/components/ui';
import { useGetCurrentUserQuery } from 'services';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const Navbar: FC<Props> = ({ className }: Props) => {
	const navigate = useNavigate();
	const isFree = useIsFree();
	const { data } = useGetCurrentUserQuery();

	const handleClick = () => {
		navigate('/registration/plan');
	};

	const handleTopExpertsClicked = () => {
		if (data) Analytics.trackTopExpertsClicked(data);
	};

	const handleChromeExtensionClicked = () => {
		if (data) Analytics.trackChromeExtensionClicked(data);
	};

	return (
		<aside className={cn(styles.aside, className)}>
			<nav>
				<Link to="search">
					<Icon className={styles.logo} icon="logo" size={32} />
				</Link>
				<NavLink to="search" className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}>
					{({ isActive }) => (
						<>
							<Icon icon="search" />
							<Text variant="grotesk/14/medium" lineHeight={120} color={isActive ? 'white' : 'grey'}>
								Search
							</Text>
						</>
					)}
				</NavLink>
				<NavLink to="projects" className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}>
					{({ isActive }) => (
						<>
							<Icon icon="folder" />
							<Text variant="grotesk/14/medium" lineHeight={120} color={isActive ? 'white' : 'grey'}>
								Projects
							</Text>
						</>
					)}
				</NavLink>
				<NavLink
					to="integrations"
					className={({ isActive }) => cn(styles.link, { [styles.linkActive]: isActive })}>
					{({ isActive }) => (
						<>
							<Icon icon="socket" />
							<Text variant="grotesk/14/medium" lineHeight={120} color={isActive ? 'white' : 'grey'}>
								Integrations
							</Text>
						</>
					)}
				</NavLink>
			</nav>
			<div className={styles.bottom}>
				{isFree && (
					<div className={styles.card}>
						<span className={styles.star}>✨</span>
						<Text
							className={styles.cardText}
							variant="grotesk/14/medium"
							lineHeight={120}
							color="dark_green">
							To unlock more credits upgrade your plan
						</Text>
						<Button type="primary" size="small" onClick={handleClick}>
							Upgrade
						</Button>
					</div>
				)}
				<NavLink
					onClick={handleTopExpertsClicked}
					to="registration/plan"
					className={styles.trophyWrapper}>
					<Icon className={styles.trophy} icon="credit-card" size={24} />
					<Text variant="grotesk/14/regular" lineHeight={120} color="grey" className={styles.text}>
						Pricing plans
					</Text>
				</NavLink>
				<Link
					onClick={handleTopExpertsClicked}
					to="https://www.getprog.ai/index-of-top-experts"
					target="_blank"
					rel="noopener,nofollow,noreferrer"
					className={styles.trophyWrapper}>
					<Icon className={styles.trophy} icon="trophy" size={24} />
					<Text variant="grotesk/14/regular" lineHeight={120} color="grey" className={styles.text}>
						Top experts
					</Text>
				</Link>
				<Link
					onClick={handleChromeExtensionClicked}
					to="https://chromewebstore.google.com/detail/progai-in-depth-insights/pplkiigdcpbacikbalacopmaklichaod"
					target="_blank"
					rel="noopener,nofollow,noreferrer"
					className={cn(styles.trophyWrapper, styles.extension)}>
					<div className={styles.chrome}>
						<Icon className={styles.trophy} icon="extension" size={20} />
					</div>
					<Text variant="grotesk/14/regular" lineHeight={120} color="grey" className={styles.text}>
						Chrome Extension
					</Text>
				</Link>
				<Link
					to="https://help.app.getprog.ai/"
					target="_blank"
					rel="noopener,nofollow,noreferrer"
					className={styles.help}>
					<Icon className={styles.question} icon="chat-circle" />
					<Text variant="grotesk/14/medium" lineHeight={120} color="white">
						Help
					</Text>
				</Link>
			</div>
		</aside>
	);
};
