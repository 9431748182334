export const formatFileSize = (size: number): string => {
	const thresh = 1024;

	if (Math.abs(size) < thresh) {
		return size + ' B';
	}

	const units = ['Kb', 'Mb', 'Gb', 'Tb'];
	let u = -1;

	do {
		size /= thresh;
		++u;
	} while (Math.round(Math.abs(size) * 10) / 10 >= thresh && u < units.length - 1);

	return `${size.toFixed(1)} ${units[u]}`;
};
