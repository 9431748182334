import { DragEvent, useEffect, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { Button, Icon, Text } from 'shared/components/ui';
import {
	RecentSearch,
	SearchFileSubmit,
	SearchForm,
	SearchTextSubmit,
	TrendingSearch
} from 'features/search/components';
import { normalizeYearsOfExperience } from 'features/search/utils';
import { useSearch } from 'features/search/hooks';
import { SearchFormParams } from 'features/search/models';
import { FileResult, SearchSkills } from 'shared/generated-models';
import { formIsEmpty, cleanObject } from 'shared/utils';
import { useUploadTextMutation } from 'services';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';
import { ChromeExtensionModal } from 'features/auth';

const getParamsCount = (values: SearchSkills | SearchFormParams) => {
	return Object.values(values).reduce((acc: number, val) => {
		if (Array.isArray(val)) {
			return acc + val.length;
		} else {
			return acc;
		}
	}, 0);
};

export const MainSearchForm = () => {
	const [currentTab, setCurrentTab] = useState<'search' | 'filters'>('search');
	const [isDragActive, setIsDragActive] = useState(false);
	const [areFiltersLoading, setAreFiltersLoading] = useState(false);
	const [areCandidatesLoading, setAreCandidatesLoading] = useState(false);
	const [text, setText] = useState('');
	const [error, setError] = useState('');
	const [uploadText] = useUploadTextMutation();

	const handleTextChange = (value: string) => {
		setText(value);
		setError('');
	};

	useEffect(() => {
		const htmlElement = document.documentElement;
		htmlElement.style.overflowY = 'scroll';

		return () => {
			htmlElement.style.overflowY = '';
		};
	}, []);

	const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		let isDraggingFiles = false;

		e.dataTransfer.types.forEach(type => {
			if (type === 'Files') isDraggingFiles = true;
		});

		setIsDragActive(isDraggingFiles);
	};

	const handleDragEnd = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		setIsDragActive(false);
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const search = useSearch();

	const formik = useFormik<SearchFormParams>({
		initialValues: {},
		validateOnMount: true,
		onSubmit: values => {
			search({ ...values, page: 0 });
		}
	});

	const paramsCount = useMemo(() => getParamsCount(formik.values), [formik.values]);

	const isSubmitDisabled = formIsEmpty(formik.values) && !text;

	const handleParsed = (searchSkills: SearchSkills | FileResult) => {
		if ('file' in searchSkills) {
			setIsDragActive(false);
			setError('Submitted file is empty');
			return;
		}
		if ('text' in searchSkills) {
			const { text, yo_experience, ...skills } = searchSkills;
			const experience = normalizeYearsOfExperience(yo_experience);
			setText(text);
			formik.setValues(cleanObject({ ...skills, yo_experience: experience }));
		} else {
			const { yo_experience, ...skills } = searchSkills;
			const experience = normalizeYearsOfExperience(yo_experience);
			formik.setValues(cleanObject({ ...skills, yo_experience: experience }));
		}

		Analytics.trackParsedText();
		setIsDragActive(false);
	};

	const handleClearFilters = () => {
		formik.setValues({});
		formik.validateForm({});
	};

	const goToFilters = () => {
		setCurrentTab('filters');
	};

	const goToSearch = () => {
		setCurrentTab('search');
	};

	const handleShowCandidates = () => {
		setAreCandidatesLoading(true);
		if (text && currentTab === 'search') {
			uploadText({ text })
				.unwrap()
				.then(payload => {
					const { yo_experience } = payload;
					const experience = normalizeYearsOfExperience(yo_experience);
					if (getParamsCount(payload) === 0) {
						setError('Please provide more detailed description');
						return;
					}
					Analytics.trackParsedText();

					search({ ...payload, yo_experience: experience, page: 0 });
					setError('');
				})
				.catch(error => {
					if (error?.data?.text[0] === 'This field may not be blank.') {
						setError('Please provide more detailed description');
						return;
					}
					setError('Something went wrong');
				})
				.finally(() => {
					setAreCandidatesLoading(false);
				});
		} else {
			formik.handleSubmit();
		}
	};

	const handleReview = () => {
		if (!text) {
			goToFilters();
			return;
		}

		setAreFiltersLoading(true);
		uploadText({ text })
			.unwrap()
			.then(payload => {
				const { yo_experience } = payload;
				const experience = normalizeYearsOfExperience(yo_experience);
				if (getParamsCount(payload) === 0) {
					setError('Please provide more detailed description');
					return;
				}
				formik.setValues({ ...payload, yo_experience: experience });
				setError('');
				goToFilters();
			})
			.catch(error => {
				if (error?.data?.text[0] === 'This field may not be blank.') {
					setError('Please provide more detailed description');
					return;
				}
				setError('Something went wrong');
			})
			.finally(() => {
				setAreFiltersLoading(false);
			});
	};

	return (
		<>
			<FormikProvider value={formik}>
				<div
					className={cn(styles.navigation, {
						[styles.navigationSearch]: currentTab === 'search',
						[styles.navigationFilters]: currentTab === 'filters'
					})}>
					<div className={styles.navigationContainer}>
						<nav className={styles.tabs}>
							<div
								className={cn(styles.tab, { [styles.tabSearch]: currentTab === 'search' })}
								onClick={goToSearch}>
								<Icon icon="stars" />
								<Text
									variant="grotesk/24/medium"
									className={styles.tabText}
									color={currentTab === 'search' ? 'black' : 'grey'}>
									AI Search
								</Text>
							</div>
							<div
								className={cn(styles.tab, { [styles.tabFilters]: currentTab === 'filters' })}
								onClick={goToFilters}>
								<Icon icon="filter-bold" />
								<Text
									variant="grotesk/24/medium"
									className={styles.tabText}
									color={currentTab === 'filters' ? 'black' : 'grey'}>
									Search by filters
								</Text>
							</div>
						</nav>
					</div>
				</div>
				<div className={styles.container}>
					<AnimatePresence mode="wait">
						{currentTab === 'search' && (
							<motion.div
								key="search"
								initial={{ x: -100, opacity: 0 }}
								animate={{ x: 0, opacity: 1 }}
								exit={{ x: -100, opacity: 0 }}
								transition={{ bounce: 0, duration: 0.2 }}>
								<div>
									<div className={styles.wrapper}>
										{isDragActive ? (
											<div
												onDragEnter={handleDragStart}
												onDragLeave={handleDragEnd}
												onDragOver={handleDragStart}>
												<SearchFileSubmit
													setError={setError}
													onParsed={handleParsed}
													onDrop={handleDrop}
													variant="full"
												/>
											</div>
										) : (
											<div
												onDragEnter={handleDragStart}
												onDragLeave={handleDragEnd}
												onDragOver={handleDragStart}>
												<SearchTextSubmit
													value={text}
													onChange={handleTextChange}
													setError={setError}
													onParsed={handleParsed}
													className={error && styles.wrapperError}
												/>
											</div>
										)}
									</div>
									{error && (
										<div className={styles.error}>
											<Icon icon="warning" />
											<Text component="p" variant="inter/14/medium" color="red">
												{error ? error : 'Upload failed, please try again'}
											</Text>
										</div>
									)}
									<div className={styles.searchButtons}>
										{!error && (text || paramsCount > 0) ? (
											<button className={styles.review} onClick={handleReview}>
												{areFiltersLoading ? (
													<Icon icon="loader" className={styles.spin} />
												) : (
													<Icon icon="filter" />
												)}
												<Text variant="inter/15/semi">Review filters</Text>
											</button>
										) : (
											<Text color="grey">
												Just type in your job description here, or upload it in a file
											</Text>
										)}
										<Button
											type="secondary"
											loading={areCandidatesLoading}
											disabled={isSubmitDisabled}
											onClick={handleShowCandidates}>
											Show candidates
										</Button>
									</div>
									<div className={styles.searchSuggestions}>
										<TrendingSearch />
										<RecentSearch />
									</div>
								</div>
							</motion.div>
						)}
						{currentTab === 'filters' && (
							<motion.div
								key="filters"
								className={styles.form}
								initial={{ x: 100, opacity: 0 }}
								animate={{ x: 0, opacity: 1 }}
								exit={{ x: 100, opacity: 0 }}
								transition={{ bounce: 0, duration: 0.2 }}>
								<SearchForm header={null} />
								<div className={styles.formButtons}>
									<div className={styles.formButtonsContainer}>
										<Button onClick={handleClearFilters} disabled={isSubmitDisabled}>
											Clear filters
										</Button>
										<Button
											type="primary"
											loading={areCandidatesLoading}
											disabled={isSubmitDisabled}
											onClick={handleShowCandidates}>
											Show candidates
										</Button>
									</div>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</FormikProvider>
			<ChromeExtensionModal />
		</>
	);
};
