import { FC, ReactNode, useState } from 'react';

import DownloadLogo from 'shared/assets/svg-icons/download.svg';
import SheetsLogo from 'shared/assets/svg-icons/sheets.svg';
import { Text, Dropdown } from 'shared/components/ui';
import { ExportEnum, TopIdsWithFiltersCandidates } from 'shared/generated-models';
import { useModalState } from 'shared/hooks/use-modal-state';
import { ExportCsvModal, ExportGoogleSheetsModal } from 'features/csv-export';
import { NoCreditsModal } from 'features/auth/components';
import { useGetCurrentUserQuery } from 'services';

import styles from './index.module.scss';

interface Props {
	children?: ReactNode;
	candidates: Omit<TopIdsWithFiltersCandidates, 'filters'> & {
		count: number;
	};
	handleExportCsv: (isFull: boolean) => void;
	handleExportGoogleSheets: (isFull: boolean) => void;
	disabled?: boolean;
	isFree?: boolean;
}

export const CsvPushMenu: FC<Props> = ({
	children,
	candidates,
	handleExportCsv,
	handleExportGoogleSheets,
	disabled,
	isFree
}) => {
	const { data } = useGetCurrentUserQuery();
	const [notEnoughCreditsModalOpen, setNotEnoughCreditsModalOpen] = useState(false);

	const menuDropdown = useModalState();
	const exportToGoogleSheetsModal = useModalState<ExportEnum>();
	const exportToEmailModal = useModalState<ExportEnum>();

	const openGoogleSheetsModal = () => {
		if (data && data.credits < candidates.count) {
			setNotEnoughCreditsModalOpen(true);
		} else {
			exportToGoogleSheetsModal.open();
		}
		menuDropdown.close();
	};

	const openCsvModal = () => {
		if (data && data.credits < candidates.count) {
			setNotEnoughCreditsModalOpen(true);
		} else {
			exportToEmailModal.open();
		}
		menuDropdown.close();
	};

	const closeModal = () => {
		exportToGoogleSheetsModal.close();
		exportToEmailModal.close();
		setNotEnoughCreditsModalOpen(false);
	};

	return (
		<>
			<Dropdown
				disabled={disabled}
				trigger={['click']}
				placement="bottomRight"
				dropdownRender={() => (
					<div className={styles.dropdown}>
						<div className={styles.dropdownList}>
							<>
								<button className={styles.button} onClick={openCsvModal}>
									<img className={styles.logo} src={DownloadLogo} alt="Lemlist logo" />
									<Text variant="inter/14/medium" className={styles.text}>
										CSV file
									</Text>
								</button>
								<button className={styles.button} onClick={openGoogleSheetsModal}>
									<img className={styles.logo} src={SheetsLogo} alt="Lemlist logo" />
									<Text variant="inter/14/medium" className={styles.text}>
										Google Sheet
									</Text>
								</button>
							</>
						</div>
					</div>
				)}
				onOpenChange={open => (open ? menuDropdown.open() : menuDropdown.close())}
				open={menuDropdown.isOpen}>
				{children}
			</Dropdown>

			{exportToGoogleSheetsModal.isOpen && (
				<ExportGoogleSheetsModal
					isFree={isFree}
					handleExport={handleExportGoogleSheets}
					onClose={() => exportToGoogleSheetsModal.close()}
					candidates={candidates}
				/>
			)}

			{exportToEmailModal.isOpen && (
				<ExportCsvModal
					isFree={isFree}
					handleExport={handleExportCsv}
					onClose={() => exportToEmailModal.close()}
					candidates={candidates}
				/>
			)}

			{notEnoughCreditsModalOpen && <NoCreditsModal onClose={closeModal} />}
		</>
	);
};
