import { Empty } from 'shared/components';
import { Icon, Text } from 'shared/components/ui';

import { CreateProjectButton } from '../CreateProjectButton';

import styles from './index.module.scss';

export const ProjectsEmpty = () => {
	return (
		<Empty
			className={styles.empty}
			title={
				<Text component="h2" variant="grotesk/16/bold" className={styles.title}>
					Create your first project
				</Text>
			}
			icon="folder-big">
			<Text className={styles.text} variant="grotesk/16/regular">
				Use projects to save candidates, build a list of candidates and export them in various formats
			</Text>
			<CreateProjectButton prefix={<Icon icon="plus-big" />}>Create project</CreateProjectButton>
		</Empty>
	);
};
