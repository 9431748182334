import {
	authService,
	csvService,
	dictionaryService,
	profileService,
	projectsService,
	searchService,
	sharedService,
	similarSkillsService,
	subscriptionService,
	integrationService
} from 'services';

export const API_MIDDLEWARES = [
	authService.middleware,
	dictionaryService.middleware,
	searchService.middleware,
	subscriptionService.middleware,
	profileService.middleware,
	similarSkillsService.middleware,
	csvService.middleware,
	sharedService.middleware,
	projectsService.middleware,
	integrationService.middleware
];
