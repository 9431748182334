type searchItem = {
	timestamp: number;
	search: string;
	text: string;
	hint: string;
};

const TAG_FIELDS = [
	'required_skills',
	'bonus_skills',
	'prog_langs',
	'job_titles',
	'top_schools',
	'companies',
	'companies_to_exclude',
	'github_repos',
	'seniority',
	'yo_employment',
	'yo_experience',
	'languages',
	'is_top_school',
	'is_first_name_female',
	'regions',
	'sub_regions',
	'countries',
	'locations',
	'likely_to_move_prob'
];

const formatTooltip = (cleanParams: any): string => {
	let result = '';

	if ('job_titles' in cleanParams) result += `${cleanParams['job_titles'].join(', ')}. `;

	const skills_tags = ['required_skills', 'bonus_skills', 'prog_langs'];
	const skill_tags: string[] = [];
	skills_tags.forEach(tag => {
		if (tag in cleanParams) {
			skill_tags.push(...cleanParams[tag]);
		}
	});
	if (skill_tags.length > 0) result += `Experince with ${skill_tags.join(', ')}. `;

	if ('companies' in cleanParams) result += `Previously worked at ${cleanParams['companies'].join(', ')}. `;
	if ('companies_to_exclude' in cleanParams)
		result += `Companies excluded: ${cleanParams['companies_to_exclude'].join(', ')}. `;
	if ('github_repos' in cleanParams)
		result += `Contributed to ${cleanParams['github_repos'].join(', ')} repositories. `;
	if ('yo_employment' in cleanParams) result += `${cleanParams['yo_employment'].join(', ')} years of employment. `;
	if ('yo_experience' in cleanParams)
		result += `${cleanParams['yo_experience'].join(', ')} years of coding experience. `;
	if ('seniority' in cleanParams) result += `Seniority: ${cleanParams['seniority'].join(', ')}. `;
	if ('is_top_school' in cleanParams) result += `Graduated from top school. `;
	if ('top_schools' in cleanParams) result += `Graduated from ${cleanParams['top_schools'].join(', ')}. `;
	if ('languages' in cleanParams) result += `Languages: ${cleanParams['languages'].join(', ')}. `;
	if ('is_first_name_female' in cleanParams) result += `First name contains a female name. `;
	if ('likely_to_move_prob' in cleanParams) result += 'Likely to move. ';

	const locations_tags = ['locations', 'countries', 'sub_regions', 'regions'];
	const location_tags: string[] = [];
	locations_tags.forEach(tag => {
		if (tag in cleanParams) {
			location_tags.push(...cleanParams[tag]);
		}
	});
	if (location_tags.length > 0) result += `From ${location_tags.join(', ')}. `;

	return result;
};

const formatSearch = (cleanParams: any): string => {
	let tags = [];

	for (let i = 0; i < TAG_FIELDS.length; i++) {
		const tag = TAG_FIELDS[i];

		if (tag === 'is_first_name_female') {
			if (tag in cleanParams) {
				tags.push('Female name');
			}
		} else if (tag === 'is_top_school') {
			if (tag in cleanParams) {
				tags.push('Top school');
			}
		} else if (tag === 'likely_to_move_prob') {
			if (tag in cleanParams) {
				tags.push('Likely to move');
			}
		} else {
			if (tag in cleanParams) tags.push(...cleanParams[tag]);
		}
	}
	const tagsFinal = tags.join(', ');

	if (tagsFinal.length <= 35) return tagsFinal;
	if (tags.length === 1) return tagsFinal.slice(0, 32) + '...';

	let finalTag = tags[tags.length - 1];
	if (finalTag.length > 15) finalTag = finalTag.slice(0, 12) + '...';

	const end = `... , ${finalTag}`;

	const prevTags = tags.slice(0, -1).join(', ');
	if (prevTags.length <= 35 - end.length - 2) return `${prevTags}, ${end}`;

	const start = tagsFinal.slice(0, 35 - end.length);
	if (start[start.length - 1] === ',') {
		return `${start} ${end}`;
	}
	return `${start}${end}`;
};

const trimSearch = (search: string): string => {
	while (search.startsWith('?')) {
		search = search.substring(1);
	}
	return search;
};

export const saveSearch = (search: string, cleanParams: any) => {
	const currentTimestamp = new Date().getTime();
	const hint = formatTooltip(cleanParams);

	const newSearchItem: searchItem = {
		timestamp: currentTimestamp,
		search: trimSearch(search),
		text: formatSearch(cleanParams),
		hint
	};

	const searchHistory = localStorage.getItem('searchHistory');
	if (searchHistory !== null) {
		let parsedSearchHistory = JSON.parse(searchHistory);
		parsedSearchHistory = parsedSearchHistory.filter((item: searchItem) => item.hint !== hint);
		parsedSearchHistory.push(newSearchItem);
		parsedSearchHistory.sort((a: searchItem, b: searchItem) => b.timestamp - a.timestamp);
		parsedSearchHistory.map((item: searchItem) => JSON.stringify(item));
		localStorage.setItem('searchHistory', JSON.stringify(parsedSearchHistory));
		return;
	}
	localStorage.setItem('searchHistory', JSON.stringify([newSearchItem]));
	return;
};
