import { ProjectsEmpty, ProjectsEmptyWithContacts } from 'features/projects';
import { useGetOpenedContactsCountQuery } from 'services';

export const Projects = () => {
	const { data } = useGetOpenedContactsCountQuery();

	if (!data) return null;

	if (data.count === 0) return <ProjectsEmpty />;

	return <ProjectsEmptyWithContacts />;
};
