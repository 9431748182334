import { FC } from 'react';

import { SkeletonRow, Text } from 'shared/components/ui';
import { ServiceEnum } from 'shared/generated-models';
import { useConnectedServices } from 'features/integrations/hooks';

import { BullhornCard } from '../BullhornCard';
import { GoogleAdsCard } from '../GoogleAdsCard';
import { GreenhouseCard } from '../GreenhouseCard';
import { HubspotCard } from '../HubspotCard';
import { LemlistCard } from '../LemlistCard';
import { LeverCard } from '../LeverCard';
import { LinkedinCard } from '../LinkedinCard';
import { SourceWhaleCard } from '../SourceWhaleCard';

import styles from './index.module.scss';

interface Props {
	onConnected?: (service: ServiceEnum) => void;
}

export const IntegrationsList: FC<Props> = ({ onConnected }: Props) => {
	const { data, isLoading } = useConnectedServices();

	return (
		<div className={styles.wrapper}>
			<Text component="h3" variant="grotesk/20/medium">
				Integrations
			</Text>
			<Text className={styles.description} paragraph variant="inter/14/regular" color="grey">
				Connect your apps to be able to export candidates' data from Prog.AI to your email outreach engines.
			</Text>
			<div className={styles.list}>
				{isLoading ? (
					<SkeletonRow height={90} />
				) : (
					<>
						<LemlistCard
							isConnected={data.includes(ServiceEnum.LEMLIST)}
							onConnected={() => onConnected?.(ServiceEnum.LEMLIST)}
						/>
						<SourceWhaleCard
							isConnected={data.includes(ServiceEnum.SOURCEWHALE)}
							onConnected={() => onConnected?.(ServiceEnum.SOURCEWHALE)}
						/>
					</>
				)}
			</div>
			<div className={styles.coming}>
				<Text variant="inter/16/medium" color="grey">
					Coming soon
				</Text>
			</div>
			<div className={styles.list}>
				<LinkedinCard />
				<GoogleAdsCard />
				<HubspotCard />
				<BullhornCard />
				<GreenhouseCard />
				<LeverCard />
			</div>
		</div>
	);
};
