import { ReactNode } from 'react';
import { ConfigProvider, Tooltip } from 'antd';

import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	isDisabled: boolean;
	delay?: number;
	children: ReactNode;
}

export const SelectProfileTooltip = ({ isDisabled, delay, children }: Props): JSX.Element => {
	if (!isDisabled) return <>{children}</>;

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<Tooltip
				arrow={false}
				title={
					<div className={styles.tooltip}>
						<Text color="dark_green">
							First you need to select profiles
						</Text>
					</div>
				}
				mouseEnterDelay={delay || 0}
				overlayClassName={styles.label}
				placement="bottom">
				{children}
			</Tooltip>
		</ConfigProvider>
	);
};
