import { useNavigate } from 'react-router-dom';

import { useGetUserSubscriptionQuery } from 'services';
import { Button, Text } from 'shared/components/ui';
import { useIsFree } from 'features/auth';
import { getDaysLeft } from 'shared/utils/format-timestamp';

import styles from './index.module.scss';

export const SearchUpdate = () => {
	const navigate = useNavigate();
	const isFree = useIsFree();
	const { data } = useGetUserSubscriptionQuery();

	const handleClick = () => {
		navigate('/registration/plan');
	};

	if (!isFree) return null;

	const daysLeft = getDaysLeft(data?.expiration_date);

	return (
		<section className={styles.update}>
			<Text variant="grotesk/14/bold">✨ You have {daysLeft} days left on a free trial</Text>
			<Button type="primary" size="small" onClick={handleClick}>
				Upgrade
			</Button>
		</section>
	);
};
