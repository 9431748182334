import GoogleAdsLogo from 'shared/assets/svg-icons/google-ads-logo.svg';

import { GeneralCard } from '../GeneralCard';

import styles from './index.module.scss';

export const GoogleAdsCard = () => {
	return (
		<GeneralCard
			icon={<img className={styles.logo} src={GoogleAdsLogo} alt="google ads logo" />}
			title="Google Ads"
			subtitle={<span>Expand candidate reach using Google Ads</span>}
		/>
	);
};
