import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import {
	Company,
	Country,
	JobTitle,
	Language,
	Location,
	Region,
	ProgLang,
	School,
	Skill,
	Subregion
} from 'shared/generated-models';

export const dictionaryService = createApi({
	reducerPath: 'dictionaryService',
	baseQuery: interceptor,
	endpoints: builder => ({
		getSkills: builder.query<Skill[], string>({
			query: search => ({
				url: `/skills/?search=${search}`,
				method: 'GET'
			})
		}),
		getJobTitles: builder.query<JobTitle[], string>({
			query: search => ({
				url: `/job_titles/?search=${search}`,
				method: 'GET'
			})
		}),
		getCompanies: builder.query<Company[], string>({
			query: search => ({
				url: `/companies/?search=${search}`,
				method: 'GET'
			})
		}),
		getCountries: builder.query<Country[], string>({
			query: search => ({
				url: `/countries/?search=${search}`,
				method: 'GET'
			})
		}),
		getLanguages: builder.query<Language[], string>({
			query: search => ({
				url: `/languages/?search=${search}`,
				method: 'GET'
			})
		}),
		getLocations: builder.query<Location[], string>({
			query: search => ({
				url: `/locations/?search=${search}`,
				method: 'GET'
			})
		}),
		getRepositories: builder.query<Location[], string>({
			query: search => ({
				url: `/repositories/?search=${search}`,
				method: 'GET'
			})
		}),
		getProgrammingLanguages: builder.query<ProgLang[], string>({
			query: search => ({
				url: `/prog_langs/?search=${search}`,
				method: 'GET'
			})
		}),
		getRegions: builder.query<Region[], string>({
			query: search => ({
				url: `/regions/?search=${search}`,
				method: 'GET'
			})
		}),
		getSchools: builder.query<School[], string>({
			query: search => ({
				url: `/schools/?search=${search}`,
				method: 'GET'
			})
		}),
		getSubregions: builder.query<Subregion[], string>({
			query: search => ({
				url: `/subregions/?search=${search}`,
				method: 'GET'
			})
		})
	})
});

export const {
	useGetSkillsQuery,
	useGetJobTitlesQuery,
	useGetCompaniesQuery,
	useGetCountriesQuery,
	useGetLanguagesQuery,
	useGetLocationsQuery,
	useGetRepositoriesQuery,
	useGetProgrammingLanguagesQuery,
	useGetRegionsQuery,
	useGetSchoolsQuery,
	useGetSubregionsQuery
} = dictionaryService;
