import BullhornLogo from 'shared/assets/svg-icons/bullhorn-logo.svg';

import { GeneralCard } from '../GeneralCard';

import styles from './index.module.scss';

export const BullhornCard = () => {
	return (
		<GeneralCard
			icon={<img className={styles.logo} src={BullhornLogo} alt="bullhorn logo" />}
			title="Bullhorn"
			subtitle={<span>Manage candidates seamlessly via Bullhorn</span>}
		/>
	);
};
