import React, { FC, useMemo, useState } from 'react';
import { ConfigProvider, Tooltip } from 'antd';
import cn from 'classnames';

import { Icon, Text, TextButton } from 'shared/components/ui';
import { getSection, normalizeArray, SectionName } from 'features/candidate/utils';

import styles from './index.module.scss';

interface Props {
	shared?: boolean;
	title: string;
	icon?: string;
	array: Array<any>;
	sectionName: SectionName;
	isClosedProfile?: boolean;
}

export const CandidateSection: FC<Props> = ({ shared, title, icon, array, sectionName, isClosedProfile }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(open => !open);
	};

	const Row = useMemo(() => getSection(sectionName), [sectionName]);

	const normalizedArray = normalizeArray(array, sectionName);

	const countToShow = sectionName === 'language' ? array.length : 2;

	if (array.length === 0) return null;

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<section>
				<div className={styles.header}>
					<div>
						<Text variant="inter/18/semi" component="h3">
							{title}
						</Text>
						{icon && <Icon className={styles.headerIcon} icon={icon} size={20} />}
					</div>
					{array.length > countToShow && (
						<>
							{isClosedProfile ? (
								<Tooltip
									arrow={false}
									title={
										<div className={styles.tooltip}>
											<Text color="dark_green">
												To view a candidate’s full information and contact details, please press
												‘Unlock Profile.’ Each profile unlock costs 1 credit.
											</Text>
										</div>
									}
									overlayClassName={styles.labelTooltip}>
									<div className={styles.locked}>
										<Text variant="inter/14/regular" color="grey">
											More
										</Text>
										<Icon icon="lock" size={16} className={cn(styles.icon, styles.iconLock)} />
									</div>
								</Tooltip>
							) : (
								<TextButton onClick={handleOpen}>
									<span>{isOpen ? 'Hide' : 'More'}</span>
									<Icon icon="chevron" className={cn(styles.icon, { [styles.iconHide]: isOpen })} />
								</TextButton>
							)}
						</>
					)}
				</div>
				<div className={shared ? styles.bodyShared : styles.body}>
					{normalizedArray.slice(0, countToShow).map((item, idx) => (
						<Row className={styles.row} data={item} key={idx} />
					))}
					{isOpen &&
						normalizedArray
							.slice(countToShow, array.length)
							.map((item, idx) => <Row className={styles.row} data={item} key={idx} />)}
				</div>
			</section>
		</ConfigProvider>
	);
};
