import { useGetCandidatesQuery, usePrefetch } from 'services';
import { SEARCH_LOADING_SIZE } from 'features/search/models';
import { SearchParams } from 'shared/generated-models';

import { useFilters } from './use-filters';

const PAGE_SIZE = 20;
const VIRTUAL_PAGES = SEARCH_LOADING_SIZE / PAGE_SIZE;

export const useGetCandidates = () => {
	const filters = useFilters();
	const prefetchPage = usePrefetch('getCandidates');

	const normalizedPageNumber = Math.floor((PAGE_SIZE * filters.page) / SEARCH_LOADING_SIZE);

	// const aiSearch = (filters as SearchParams).id;
	const aiSearch = undefined;

	const { data, isFetching, isError } = useGetCandidatesQuery({
		...filters,
		id: undefined,
		ai_search: aiSearch ? +aiSearch : undefined,
		page: normalizedPageNumber
	});

	if (filters.page % VIRTUAL_PAGES === 3) {
		prefetchPage({ ...filters, page: normalizedPageNumber + 1 });
	}

	const page = filters.page || 0;

	const pageNumberOnCurrentSegment = page % (SEARCH_LOADING_SIZE / PAGE_SIZE);

	const pagedResults =
		data?.results.slice(PAGE_SIZE * pageNumberOnCurrentSegment, PAGE_SIZE * (pageNumberOnCurrentSegment + 1)) || [];

	const pagedData = { ...data, results: pagedResults };

	const totalLoaded = data
		? Math.min(
				Math.ceil((PAGE_SIZE * filters.page + 1) / SEARCH_LOADING_SIZE) * SEARCH_LOADING_SIZE,
				Math.floor((PAGE_SIZE * filters.page + 1) / SEARCH_LOADING_SIZE) * SEARCH_LOADING_SIZE +
					data.results.length
		  )
		: 0;

	return { data: pagedData, isFetching, isError, totalLoaded };
};
