import { useNavigate } from 'react-router-dom';

import { Text } from 'shared/components/ui';
import { User } from 'shared/generated-models';
import { BillingPlan } from 'shared/models';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

interface Props {
	data: User;
}

export const UpgradeButton = ({ data }: Props) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/registration/plan');
		Analytics.trackUpgradeClicked(data);
	};

	const shouldShowUpgrade = data.billing_plan === BillingPlan.FREE;

	return (
		<>
			{shouldShowUpgrade && (
				<div className={styles.button} onClick={handleClick}>
					<Text variant="inter/14/medium" color="white">
						Upgrade
					</Text>
				</div>
			)}
		</>
	);
};
