import { FC } from 'react';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, openNotification, openNotificationUnlocked, Text } from 'shared/components/ui';
import { useGetCurrentUserQuery } from 'services';
import { Analytics } from 'features/analytics';
import { ProfileVariant, useOpenContacts } from 'features/candidate';

interface Props {
	onClose: () => void;
	id: number;
	variant: ProfileVariant;
}

export const ContactModal: FC<Props> = ({ id, onClose, variant }: Props) => {
	const [openContacts] = useOpenContacts(variant);
	const { data } = useGetCurrentUserQuery();

	const handleGet = async () => {
		if (data && data.credits < 1) {
			openNotification({
				text: 'Not enough credits'
			});
		} else {
			try {
				await openContacts(id).unwrap();
				Analytics.trackGetContact(id);
				openNotificationUnlocked({ closable: true });
			} catch (e) {
				console.error(e);
			}
		}
		onClose();
	};

	return (
		<CenterModal onClose={onClose} icon="credits" iconColor="green">
			<Text variant="inter/20/medium" color="dark_blue">
				Getting candidate&rsquo;s contact requires 1&nbsp;credit
			</Text>
			<ModalFooter>
				<Button type="ghost" onClick={onClose}>
					Cancel
				</Button>
				<Button type="primary" onClick={handleGet}>
					Unlock profile
				</Button>
			</ModalFooter>
		</CenterModal>
	);
};
