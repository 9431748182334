import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Progress } from 'antd';

import { Empty } from 'shared/components';
import { Button, Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	progress: number | null;
	count: number | null;
}

export const ProjectCandidatesListEmpty: FC<Props> = ({ progress, count }: Props) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/search');
	};

	return (
		<Empty
			title={
				<Text component="h2" variant="grotesk/16/bold">
					{count === null || isNaN(count)
						? 'No candidates yet'
						: `Adding ${count} candidates to this project`}
				</Text>
			}
			icon="candidates-empty"
			className={styles.empty}>
			{progress !== null ? (
				<Progress
					type="circle"
					percent={progress * 100}
					showInfo={false}
					strokeColor="#57d18a"
					width={40}
					strokeLinecap="butt"
				/>
			) : (
				<Button type="primary" prefix={<Icon icon="search" />} onClick={handleClick}>
					Find candidates
				</Button>
			)}
		</Empty>
	);
};
