import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import cn from 'classnames';

import { Button, FormikInput, Icon, Text } from 'shared/components/ui';
import { authLink, emptyUser, useGetCurrentUserQuery, useUpdateUserMutation } from 'services';
import { profileFormValidationSchema } from 'features/registration';
import { ProfileEditForm } from 'shared/models';
import { Analytics } from 'features/analytics';
import { normalizePhoneNumber } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const RegistrationForm: FC<Props> = ({ className }: Props) => {
	const navigate = useNavigate();

	const { data } = useGetCurrentUserQuery();
	const initial = data || emptyUser;
	const [updateUser] = useUpdateUserMutation();

	const handleSubmit = async (values: ProfileEditForm) => {
		try {
			const newUser = {
				...initial,
				first_name: values.first_name,
				last_name: values.last_name,
				phone_number: normalizePhoneNumber(values.phone_number),
				company: values.company
			};

			await updateUser(newUser);

			Analytics.trackRegistration(newUser);

			navigate('/search?is_new=true');
		} catch (e) {
			console.error(e);
		}
	};

	const formik = useFormik<ProfileEditForm>({
		validationSchema: profileFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: true,
		initialValues: {
			first_name: initial.first_name,
			last_name: initial.last_name,
			phone_number: initial.phone_number,
			company: initial.company
		},
		onSubmit: values => handleSubmit(values)
	});

	const handleClick = () => {
		window.open('https://www.getprog.ai/', '_blank');
	};

	return (
		<div className={cn(styles.login, className)}>
			<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
			<div className={styles.card}>
				<div className={styles.cardTop}>
					<Text variant="inter/24/semi">Complete registration</Text>
					<FormikProvider value={formik}>
						<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
							<Text component="p" variant="inter/14/medium" className={styles.emailTitle} color="black">
								Email
							</Text>
							<Text component="p" color="grey" className={styles.email}>
								{data?.email}
							</Text>
							<FormikInput className={styles.input} name="first_name" label="First Name" size="large" placeholder="Your name" />
							<FormikInput className={styles.input} name="last_name" label="Last Name" size="large" placeholder="Your surname" />
							<FormikInput
							    className={styles.input}
								name="phone_number"
								label="Phone number"
								size="large"
								placeholder="+1 (343) 343 - 3432"
							/>
							<FormikInput
								name="company"
								label="Company"
								size="large"
								placeholder="Your company"
								className={cn(styles.input, styles.formCompany)}
							/>
							<div className={styles.buttons}>
								<Button className={styles.loginButton} type="primary" block onClick={formik.handleSubmit} disabled={!formik.isValid}>
									Start hiring
								</Button>
								<a className={styles.emailLink} href={authLink}>
									Change email
								</a>
							</div>
						</Form>
					</FormikProvider>
				</div>
			</div>
			<span></span>
		</div>
	);
};
