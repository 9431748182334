export const normalizePhoneNumber = (phone_number?: string) => {
	if (phone_number) {
		let formattedNumber = phone_number.charAt(0) === '+' ? '+' : '';
		for (let i = 0; i < phone_number.length; i++) {
			if (/\d/.test(phone_number[i])) {
				formattedNumber += phone_number[i];
			}
		}
		return formattedNumber;
	}
	return phone_number;
};
