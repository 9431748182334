import { FC, useState } from 'react';

import { Button, Dropdown, Icon, Text } from 'shared/components/ui';
import { Project } from 'shared/generated-models';

import { DeleteProjectsModal } from '../DeleteProjectsModal';

import styles from './index.module.scss';

interface Props {
	projects: Project[];
	disabled?: boolean;
	onDelete: () => void;
}

export const SidebarMenu: FC<Props> = ({ projects, disabled, onDelete }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
		setIsMenuOpen(false);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Dropdown
				trigger={['click']}
				onOpenChange={setIsMenuOpen}
				open={isMenuOpen}
				dropdownRender={() => (
					<div className={styles.dropdown}>
						<button disabled={disabled} className={styles.button} onClick={openModal}>
							<Icon icon="trash" />
							<Text variant="inter/14/medium">Delete</Text>
						</button>
					</div>
				)}>
				<Button type="ghost" className={styles.dropdownTrigger}>
					<Icon icon="dots" />
				</Button>
			</Dropdown>

			{isModalOpen && <DeleteProjectsModal projects={projects} onClose={closeModal} onDelete={onDelete} />}
		</>
	);
};
