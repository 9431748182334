import { FC } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ConfigProvider, Modal } from 'antd';

import { Icon, Text } from 'shared/components/ui';
import { useGetCurrentUserQuery } from 'services';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

interface Props {
	onClose?: () => void;
}

export const ChromeExtensionModal: FC<Props> = ({ onClose }: Props) => {
	const { data } = useGetCurrentUserQuery();
	const [searchParams] = useSearchParams();

    const navigate = useNavigate();

	const isNew = searchParams.get('is_new');

	const handleClose = () => {
        navigate('/search');
	};

	const handleClick = () => {
		window.open('https://www.getprog.ai/', '_blank');
	};

	const handleChromeExtensionClicked = () => {
		window.open(
			'https://chromewebstore.google.com/detail/progai-in-depth-insights/pplkiigdcpbacikbalacopmaklichaod',
			'_blank'
		);
		if (data) Analytics.trackChromeExtensionClicked(data);
	};

	if (isNew)
		return (
			<ConfigProvider theme={{ components: { Modal: { borderRadiusLG: 16 } } }}>
				<Modal
					destroyOnClose={true}
					centered={true}
					footer={null}
					closable={false}
					onCancel={handleClose}
					width={1200}
					zIndex={11000}
					open={true}>
					<div className={styles.container}>
						<div className={styles.wrapper}>
							<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
							<div className={styles.title}>
								<Text variant="inter/32/medium" color="dark_blue">
									Install Prog.AI
								</Text>
								<Text variant="inter/32/medium" color="dark_blue">
									Chrome Extension.
								</Text>
								<Text variant="inter/32/bold" color="dark_blue">
									It's free!
								</Text>
							</div>
							<ul>
								<li>
									<Text variant="inter/16/regular" component="p">
										See in-depth technical profiles of software engineers on LinkedIn or GitHub
									</Text>
								</li>
								<li>
									<Text variant="inter/16/regular" component="p">
										Seamless integration with Linkedin Recruiter
									</Text>
								</li>
								<li>
									<Text variant="inter/16/regular" component="p">
										Build bulk lists of candidates quickly
									</Text>
								</li>
								<li>
									<Text variant="inter/16/regular" component="p">
										Get contact details to reach out immediately
									</Text>
								</li>
							</ul>
							<div className={styles.footer}>
								<button className={styles.button} onClick={handleChromeExtensionClicked}>
									<Icon icon="extension" size={32} />
									<Text variant="inter/16/medium" color="white">
										Install Chrome Extension
									</Text>
								</button>
								<div className={styles.footerContinue} onClick={handleClose}>
									<Text variant="inter/15/semi" color="black">
										Continue to Prog.AI search
									</Text>
								</div>
							</div>
						</div>
						<div className={styles.image}></div>
					</div>
				</Modal>
			</ConfigProvider>
		);

	return null;
};
