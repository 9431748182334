import { FC, useState } from 'react';
import { DraggerProps } from 'antd/es/upload';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';
import { Uploader } from 'shared/components';
import { SearchSkills } from 'shared/generated-models';
import { useUploadFileMutation } from 'services';

import styles from './index.module.scss';

interface Props extends DraggerProps {
	onParsed: (searchSkills: SearchSkills) => void;
	variant: 'small' | 'full';
	setError: (error: string) => void;
}

const MAX_SIZE = 5 * 1024 * 1024;
const ALLOWED_TYPES = ['text/plain'];

export const SearchFileSubmit: FC<Props> = ({ onParsed, variant, setError, className, ...otherProps }: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [uploadFile] = useUploadFileMutation();

	const handleUpload = (file: File) => {
		setIsLoading(true);

		uploadFile(file)
			.unwrap()
			.then(payload => {
				onParsed(payload);
				setError('');
			})
			.catch(error => {
				setError('Upload failed, please try again');
			})
			.finally(() => {
				setIsLoading(false);
			});

		return false;
	};

	const stopLoading = () => {
		onParsed({});
		setIsLoading(false);
	};

	return (
		<Uploader
			maxCount={1}
			maxSize={MAX_SIZE}
			beforeUpload={handleUpload}
			onError={stopLoading}
			setError={setError}
			className={cn(className, { [styles.small]: variant === 'small' })}
			mimeTypes={ALLOWED_TYPES}
			{...otherProps}>
			{variant === 'full' && (
				<div className={styles.search}>
					<div className={styles.searchContainer}>
						<div className={styles.searchInfo}>
							<Icon icon="file-drop" className={styles.searchInfoIcon} />
							<Text color="light_blue">{isLoading ? 'Loading...' : 'Drop a file here'}</Text>
						</div>
					</div>
				</div>
			)}
			{variant === 'small' && (
				<div className={styles.smallIcon}>
					{isLoading ? (
						<Icon icon="loader" size={24} className={styles.spin} />
					) : (
						<Icon icon="file-drop-small" size={24} />
					)}
				</div>
			)}
		</Uploader>
	);
};
