import { ProgAICandidate } from 'shared/generated-models';

export const formatEducation = (school: string, data: ProgAICandidate['profile']) => {
	if (!data.educations || data.educations.length === 0) return school;
	const education = data.educations.find(education => education.campus === school);
	if (!education) return school;

	const firstPart = [education.specialization, education.major].filter(Boolean).join(', ');
	return [firstPart, education.campus].filter(Boolean).join(' at ');
};
