function isExists<T>(value: T): value is Exclude<T, null | undefined> {
	return value !== undefined && value !== null;
}

export function cleanObject<T extends Record<string, any>>(obj: T, removeEmptyArray?: boolean): Record<keyof T, any> {
	const cleanedObject: Record<string, any> = {};

	Object.keys(obj).forEach(key => {
		if (removeEmptyArray === true) {
			if (Array.isArray(obj[key]) && obj[key].length === 0) {
				return;
			}
		}

		if (!isExists(obj[key])) {
			return;
		}

		cleanedObject[key] = obj[key];
	});

	return cleanedObject as T;
}
