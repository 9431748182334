import { FC } from 'react';

import LemlistLogo from 'shared/assets/svg-icons/lemlist-logo.svg';
import { CenterModal } from 'shared/components';
import { SkeletonRow, Text } from 'shared/components/ui';
import { useGetCampaignsQuery } from 'services';
import { Campaign, ServiceEnum } from 'shared/generated-models';

import { CampaignItem } from '../CampaignItem';

import styles from './index.module.scss';

interface Props {
	onSelect: (item: Campaign) => void;
	onClose: () => void;
}

export const SelectLemlistCampaignModal: FC<Props> = ({ onSelect, onClose }: Props) => {
	const { data, isFetching } = useGetCampaignsQuery(ServiceEnum.LEMLIST, {
		refetchOnMountOrArgChange: true
	});

	return (
		<CenterModal width={520} className={styles.root} onClose={onClose} showClose>
			<div className={styles.header}>
				<div className={styles.logo}>
					<img src={LemlistLogo} alt="Lemlist logo" />
				</div>
				<Text variant="inter/20/medium" color="dark_blue">
					Export contacts to Lemlist
				</Text>
			</div>
			{isFetching ? (
				<div>
					<SkeletonRow height={60} /> <br />
					<SkeletonRow height={60} /> <br />
					<SkeletonRow height={60} />
				</div>
			) : (
				<div>
					<Text component="h5" variant="inter/14/regular" color="grey">
						Select list
					</Text>

					{data && data.campaigns.length > 0 ? (
						<div className={styles.campaigns}>
							{data.campaigns.map(item => (
								<CampaignItem
									key={item.campaignId}
									title={item.campaignName}
									subtitle={item.labels?.join(', ')}
									className={styles.campaign}
									onClick={() => onSelect(item)}
								/>
							))}
						</div>
					) : (
						<div className={styles.empty}>
							<Text component="h5" variant="inter/16/regular" color="black">
								No active campaigns.
							</Text>
						</div>
					)}
				</div>
			)}
		</CenterModal>
	);
};
