import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { setHeaderTitle } from 'widgets/Header';
import { ProjectsSidebar } from 'features/projects';
import { useGetProjectsListQuery } from 'services';
import { isPlural } from 'shared/utils';

import styles from './index.module.scss';

export const ProjectsLayout = () => {
	const dispatch = useAppDispatch();
	const { data } = useGetProjectsListQuery();

	useEffect(() => {
		data &&
			dispatch(
				setHeaderTitle(
					isPlural(data.projects_count) ? `${data.projects_count} Projects` : `${data.projects_count} Project`
				)
			);
	}, [dispatch, data]);

	return (
		<section className={styles.layout}>
			<ProjectsSidebar className={styles.sidebar} />
			<section className={styles.content}>
				<Outlet />
			</section>
		</section>
	);
};
