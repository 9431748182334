import { useEffect, useRef } from 'react';

import { TypedText } from 'features/search/models';
import { Text } from 'shared/components/ui';
import { sleep } from 'shared/utils';
import { darkGreen, darkGrey, lightBlue, orange, purple } from 'configs';

import styles from './index.module.scss';

export const SearchTextPlaceholder = () => {
	const textRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		writeFirstPlaceholder();
	}, [textRef]);

	const writeText = async (texts: TypedText[]) => {
		if (!textRef.current) return;

		for (let i = 0; i < texts.length; i++) {
			const current = texts[i];

			const span = document.createElement('span');
			span.style.color = current.color;
			textRef.current.append(span);

			for (let j = 0; j < current.text.length; j++) {
				await sleep(75);
				span.textContent += current.text[j];

				if (j === current.text.length - 1 && i !== texts.length - 1) {
					await sleep(75);
					span.textContent += ' ';
				}
			}
		}
	};

	const writeFirstPlaceholder = async () => {
		await writeText([
			{ text: 'I’m looking for a', color: darkGrey },
			{ text: 'DevOps Engineer', color: orange },
			{ text: 'in', color: darkGrey },
			{ text: 'Brazil', color: lightBlue },
			{ text: 'with knowledge of', color: darkGrey },
			{ text: 'Azure, Docker, Kubernetes', color: darkGreen }
		]);
		await sleep(2000);
		clearPlaceholder();
		writeSecondPlaceholder();
	};

	const writeSecondPlaceholder = async () => {
		await writeText([
			{ text: 'I’m looking for a', color: darkGrey },
			{ text: 'remote', color: lightBlue },
			{ text: 'Junior Frontend Engineer', color: orange },
			{ text: 'for designing and developing', color: darkGrey },
			{ text: 'web applications', color: darkGreen },
			{ text: 'with modern front end technology stack, e.g.', color: darkGrey },
			{ text: 'JavaScript, ReactJS, Redux', color: darkGreen }
		]);
		await sleep(2000);
		clearPlaceholder();
		writeThirdPlaceholder();
	};

	const writeThirdPlaceholder = async () => {
		await writeText([
			{ text: 'I’m looking for a', color: darkGrey },
			{ text: 'Data Scientist', color: orange },
			{ text: 'in', color: darkGrey },
			{ text: 'Germany, Poland', color: lightBlue },
			{ text: 'or', color: darkGrey },
			{ text: 'Austria', color: lightBlue },
			{ text: 'with a minimum', color: darkGrey },
			{ text: '8 years of experience', color: purple },
			{ text: 'in', color: darkGrey },
			{ text: 'ML Modeling, Ranking, Recommendations, or Personalization systems', color: darkGreen }
		]);
		await sleep(2000);
		clearPlaceholder();
		writeFourthPlaceholder();
	};

	const writeFourthPlaceholder = async () => {
		await writeText([
			{ text: 'I’m looking for a', color: darkGrey },
			{ text: 'Lead Java/Golang Software Engineer', color: orange },
			{ text: 'with a strong understanding of', color: darkGrey },
			{ text: 'blockchain technology', color: darkGreen },
			{ text: 'and', color: darkGrey },
			{ text: 'zkEVN', color: darkGreen },
			{ text: 'layer 2 protocol. Familiarity with', color: darkGrey },
			{ text: 'zero-knowledge proof systems', color: darkGreen },
			{ text: 'is a plus', color: darkGrey }
		]);
		await sleep(2000);
		clearPlaceholder();
		writeFirstPlaceholder();
	};

	const clearPlaceholder = () => {
		if (textRef.current) {
			textRef.current.innerHTML = '';
		}
	};

	return (
		<Text variant="inter/16/regular" component="p" color="grey" className={styles.placeholder}>
			<span ref={textRef}></span>
		</Text>
	);
};
