import React, { FC } from 'react';
import { Pagination } from 'antd';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	total: number;
	handleOnChange: (page: number) => void;
}

const customItemRender =
	(finalPage: number) =>
	(page: number, type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next', originalElement: React.ReactNode) => {
		if (type === 'prev') {
			return (
				<button disabled={page === 1} className={cn(styles.paginationButton, styles.paginationPrevious)}>
					<Icon icon="chevron" />
				</button>
			);
		}
		if (type === 'next') {
			return (
				<button disabled={page === finalPage} className={cn(styles.paginationButton, styles.paginationNext)}>
					<Icon icon="chevron" />
				</button>
			);
		}
		if (type === 'page') {
			return (
				<Text variant="inter/14/bold" color="grey">
					{page}
				</Text>
			);
		}
		return originalElement;
	};

export const AntPagination: FC<Props> = ({ total, handleOnChange }: Props) => {
	return (
		<div className={styles.paginationWrapper}>
			<Pagination
				defaultCurrent={1}
				total={total}
				onChange={handleOnChange}
				itemRender={customItemRender(Math.ceil(total / 10))}
				showSizeChanger={false}
			/>
		</div>
	);
};
