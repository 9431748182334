import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import cn from 'classnames';

import { SubscriptionResultCard } from 'features/subscription';
import { Button, Text } from 'shared/components/ui';
import PaymentSuccessIcon from 'shared/assets/svg-icons/payment-success.svg';
import { useGetBillingDataQuery } from 'services';
import { currencyFormatter } from 'shared/utils';
import { IntervalEnum } from 'shared/generated-models';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

export const PaymentSuccess = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { session_id } = qs.parse(location.search);

	const { data } = useGetBillingDataQuery(session_id as string);

	const handleClick = () => {
		navigate('/search');
	};

	useEffect(() => {
		if (data) {
			setTimeout(() => {
				Analytics.trackPaymentSuccess(data);
			}, 1);
		}
	}, [data]);

	return (
		<SubscriptionResultCard className={cn(styles.card, { [styles.cardVisible]: !!data })}>
			{data && (
				<>
					<div className={styles.success}>
						<img src={PaymentSuccessIcon} className={styles.successIcon} alt="payment success icon" />
						<Text component="h1" variant="grotesk/24/bold" lineHeight={106}>
							Payment was successful!
						</Text>
						<Text color="grey" component="p" className={styles.description}>
							You now have a&nbsp;<span className={styles.capitalize}>{data.billing_plan}</span>{' '}
							subscription with {data.credits} credits per month. Enjoy!
						</Text>
					</div>
					<div className={styles.info}>
						<div className={styles.infoRowWrapper}>
							<div className={styles.infoRow}>
								<Text variant="inter/15/semi" className={styles.capitalize}>
									{data.billing_plan}
								</Text>
								<Text variant="inter/15/semi">{currencyFormatter.format(data.amount / 100)}</Text>
							</div>
							<Text color="grey">Paid {data.interval === IntervalEnum.MONTH ? 'monthly' : 'yearly'}</Text>
						</div>
						<div className={styles.infoRow}>
							<Text variant="inter/15/semi">Total Amount</Text>
							<Text variant="inter/15/semi">{currencyFormatter.format(data.amount / 100)}</Text>
						</div>
					</div>
					<Text color="grey">
						If you want to upgrade, add credits or cancel subscription{' '}
						<a href="/" className={styles.contact}>
							contact us
						</a>
					</Text>
					<Button block type="primary" onClick={handleClick}>
						Start hiring with Prog.AI
					</Button>
				</>
			)}
		</SubscriptionResultCard>
	);
};
