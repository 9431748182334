export const AnalyticsProperties = {
	FIRST_NAME: 'First name',
	LAST_NAME: 'Last name',
	EMAIL: 'Email',
	REGISTRATION_DATE: 'Registration date',
	BILLING_PLAN: 'Billing plan',
	COMPANY: 'Company',
	LAST_LOGIN: 'Last login',
	NUMBER_OF_LOGINS: '# of logins',
	NUMBER_OF_SEARCHES: '# of searches',
	CANDIDATES_COUNT: 'Candidates count',
	REQUIRED: 'Required',
	BONUS: 'Bonus',
	TITLE: 'Title',
	EMPLOYMENT: 'Employment',
	EXPERIENCE: 'Experience',
	SENIORITY: 'Seniority',
	LOCATION: 'Location',
	COUNTRY: 'Country',
	COMPANIES: 'Companies',
	EXCLUDED: 'Excluded',
	REPOS: 'Repos',
	PAGE_NUMBER: 'Number of page',
	PROFILE_ID: 'Profile id',
	PROFILE_IDS: 'Profile ids',
	NUMBER_OF_PROFILE_OPENS: '# of opens',
	NUMBER_OF_EXPORTS: '# of exports',
	NUMBER_OF_EXPORTED_PROFILES: 'Number of exported profiles',
	NUMBER_OF_SHARINGS: '# of sharings',
	NUMBER_OF_SHARED_PROFILE_OPENS: '# of shared profile opens',
	INTERVAL: 'Interval',
	SOURCE: 'Source',
	LINK: 'link',
	NUMBER_OF_REDIRECTS: '# of redirects',
	NUMBER_OF_REVEALS: '# or reveals',
	NUMBER_OF_COPIES: '# or copies',
	PROJECT_ID: 'Project id',
	NUMBER_OF_CANDIDATES: 'Candidates',
	MIXPANEL_EMAIL: '$email',
	MIXPANEL_FIRST_NAME: '$first_name',
	MIXPANEL_LAST_NAME: '$last_name',
	MIXPANEL_NAME: '$name',
	SERVICE: 'Service',
	NUMBER_OF_PUSHED: '# of pushed',
	NUMBER_OF_PUSHES: '# of pushes',
	NUMBER_OF_PARSED: '# of parsed',
	REGION: 'Region',
	SUBREGION: 'Subregion',
	LANGUAGES: 'Languages',
	PROGRAMMING_LANGUAGES: 'Programming languages',
	TOP_SCHOOLS: 'Top schools',
	IS_TOP_SCHOOLS: 'From top school',
	IS_FEMALE: 'First name female',
	LIKELY_TO_MOVE: 'Likely to move'
} as const;
