import LinkedinLogo from 'shared/assets/svg-icons/linkedin-logo-large.svg';

import { GeneralCard } from '../GeneralCard';

import styles from './index.module.scss';

export const LinkedinCard = () => {
	return (
		<GeneralCard
			icon={<img className={styles.logo} src={LinkedinLogo} alt="linkedin logo" />}
			title="Linkedin Ads"
			subtitle={<span>Efficiently target candidates on LinkedIn</span>}
		/>
	);
};
