import { useNavigate } from 'react-router-dom';

import { Button, Text } from 'shared/components/ui';
import { ProfileSubheader } from 'features/profile';
import { useGetUserSubscriptionQuery } from 'services';
import { currencyFormatter, NUMBERS_FORMAT, formatDate, PROGAI_EMAIL } from 'shared/utils';
import { BillingPlan } from 'shared/models';
import { ProfileSubscriptionSkeleton } from 'features/profile';

import styles from './index.module.scss';

export const ProfileSubscription = () => {
	const navigate = useNavigate();
	const { data, isLoading } = useGetUserSubscriptionQuery();

	const handleUpgrade = () => {
		navigate('/registration/plan');
	};

	return (
		<section>
			<ProfileSubheader>
				<Text variant="inter/16/bold" component="h3">
					Subscription
				</Text>
				{data && data.billing_plan === BillingPlan.FREE && (
					<Button type="primary" onClick={handleUpgrade}>
						Upgrade
					</Button>
				)}
			</ProfileSubheader>
			<main className={styles.content}>
				<div className={styles.plan}>
					<Text variant="inter/14/medium" className={styles.planLeft}>
						Your current plan
					</Text>
					<div className={styles.planRight}>
						<div className={styles.planInfo}>
							{isLoading && <ProfileSubscriptionSkeleton />}
							{data && (
								<>
									<Text component="h4" variant="grotesk/20/bold" className={styles.planTitle}>
										{data.billing_plan}
									</Text>
									<Text
										component="p"
										variant="inter/16/regular"
										color="grey"
										className={styles.planPrice}>
										<Text variant="inter/16/bold" color="black">
											{currencyFormatter.format(data.amount_paid / 100)}
										</Text>{' '}
										/ {data.billing_plan === BillingPlan.FREE ? '14 days' : `a ${data.interval}`}
									</Text>
									<Text component="p" color="grey">
										Your{' '}
										{data.billing_plan === BillingPlan.FREE
											? 'subscription expires'
											: 'next bill is'}{' '}
										on {formatDate(data.expiration_date, NUMBERS_FORMAT)}
									</Text>
								</>
							)}
						</div>
						{data && data.billing_plan !== BillingPlan.FREE && (
							<Text component="p" variant="inter/14/medium">
								If you want to upgrade, add credits or cancel subscription{' '}
								<a href={`mailto:${PROGAI_EMAIL}`} className={styles.contact}>
									contact us
								</a>
							</Text>
						)}
					</div>
				</div>
			</main>
		</section>
	);
};
