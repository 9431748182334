import { FC, useRef, useState } from 'react';
import cn from 'classnames';

import { Button, Icon } from 'shared/components/ui';
import { SearchSkills } from 'shared/generated-models';
import { SearchFileSubmit, SearchTextPlaceholder } from 'features/search/components';
import { useAutosizeTextarea } from 'features/search/hooks';

import styles from './index.module.scss';

interface Props {
	onParsed: (searchSkills: SearchSkills) => void;
	setError: (error: string) => void;
	className?: string;
	value: string;
	onChange: (value: string) => void;
}

export const SearchTextSubmit: FC<Props> = ({ onParsed, setError, className, value, onChange }: Props) => {
	const [isFocused, setIsFocused] = useState(false);
	const ref = useRef<HTMLTextAreaElement | null>(null);

	useAutosizeTextarea(ref.current, value);

	const handleFocus = () => {
		setIsFocused(true);

		ref.current?.focus();
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	const clearText = () => {
		onChange('');
		setIsFocused(false);
	};

	return (
		<div
			className={cn(styles.search, className, { [styles.searchFocus]: isFocused })}
			tabIndex={1}
			onFocus={handleFocus}
			onBlur={handleBlur}>
			<Icon icon="search" size={24} className={styles.searchIcon} />
			<div className={styles.wrapper}>
				{isFocused || value ? (
					<textarea
						ref={ref}
						autoFocus
						onBlur={handleBlur}
						value={value}
						rows={1}
						onChange={e => onChange(e.target.value)}
						className={styles.searchInput}
					/>
				) : (
					<SearchTextPlaceholder />
				)}
			</div>
			<div className={styles.searchRight}>
				<Button
					type="ghost"
					className={cn(styles.searchClose, { [styles.invisible]: !value })}
					onClick={clearText}>
					<Icon icon="close-big" />
				</Button>
				<SearchFileSubmit
					className={cn({ [styles.invisible]: !!value })}
					setError={setError}
					onParsed={onParsed}
					variant="small"
				/>
			</div>
		</div>
	);
};
