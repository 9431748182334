import { configureStore } from '@reduxjs/toolkit';

import { API_MIDDLEWARES } from './middlewares';
import { rootReducer } from './reducers';

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(API_MIDDLEWARES)
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
