import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'shared/components/ui';
import { BillingPlan } from 'shared/models';
import { useLocalStorage } from 'shared/hooks';
import { PROGAI_EMAIL } from 'shared/utils';
import { useCreateCheckoutSessionMutation, useGetCurrentUserQuery } from 'services';

interface Props {
	className?: string;
	billingPlan: string;
	priceId?: string;
}

const capitalize = (plan: string): string => {
	return plan.charAt(0).toUpperCase() + plan.slice(1);
};

export const CardButton = ({ className, billingPlan, priceId }: Props) => {
	const [url, setUrl] = useState('');
	const [isNewUser, setIsNewUser] = useLocalStorage('isNewUser', true);

	const navigate = useNavigate();

	const { data } = useGetCurrentUserQuery();
	const [createSession] = useCreateCheckoutSessionMutation();

	useEffect(() => {
		if (url) {
			window.open(url, '_self', 'noopener,nofollow,noreferrer');
		}
	}, [url]);

	const handleFreeOrCurrent = () => {
		navigate('/');
	};

	const handleSubscription = () => {
		priceId &&
			createSession({
				price_id: priceId
			})
				.unwrap()
				.then(link => {
					setUrl(link);
				});
	};

	const handleClick = () => {
		if (billingPlan === data?.billing_plan || billingPlan === BillingPlan.FREE) {
			handleFreeOrCurrent();
		} else {
			handleSubscription();
		}
		setIsNewUser(false);
	};

	const text = billingPlan === data?.billing_plan ? 'Current plan' : `Change to ${capitalize(billingPlan)}`;

	if (
		billingPlan !== data?.billing_plan &&
		!isNewUser &&
		data?.billing_plan !== BillingPlan.FREE &&
		data?.billing_plan !== BillingPlan.EXPIRED
	)
		return (
			<Button
				className={className}
				component="a"
				href={`mailto:${PROGAI_EMAIL}`}
				type={billingPlan === BillingPlan.RECRUITER ? 'primary' : 'default'}>
				Change to {capitalize(billingPlan)}
			</Button>
		);

	return (
		<Button
			className={className}
			block
			onClick={handleClick}
			disabled={
				(billingPlan === data?.billing_plan && billingPlan !== BillingPlan.FREE) ||
				(billingPlan === BillingPlan.FREE && !isNewUser)
			}
			type={billingPlan === BillingPlan.RECRUITER ? 'primary' : 'default'}>
			{text}
		</Button>
	);
};
