import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';

import { Button, FormikInput, Text, openNotification } from 'shared/components/ui';
import SourceWhaleLogo from 'shared/assets/svg-icons/source-whale-logo.svg';
import { OutreachFormValidationSchema, OutreachServiceSubmitFormDto } from 'features/integrations/models';
import { useSubmitOutreachServiceMutation } from 'services';
import { ServiceEnum } from 'shared/generated-models';

import { IntegrationCard } from '../IntegrationCard';

import styles from './index.module.scss';

interface Props {
	isConnected: boolean;
	onConnected: () => void;
}

export const SourceWhaleCard = ({ isConnected, onConnected }: Props) => {
	const [submitOutreachService] = useSubmitOutreachServiceMutation();

	const formik = useFormik<OutreachServiceSubmitFormDto>({
		validationSchema: OutreachFormValidationSchema,
		initialValues: { key: '' },
		onSubmit: async values => {
			try {
				await submitOutreachService({
					service: ServiceEnum.SOURCEWHALE,
					api_key: values.key
				}).unwrap();

				openNotification({
					key: 'connect-integration',
					text: 'SourceWhale has been successfully connected',
					closable: true
				});

				onConnected();
			} catch (e: any) {
				formik.setFieldError('key', e?.data?.detail || 'Something is wrong. Try again');
			}
		}
	});

	return (
		<IntegrationCard
			isConnected={isConnected}
			icon={<img className={styles.logo} src={SourceWhaleLogo} alt="SourceWhale logo" />}
			title="SourceWhale"
			subtitle={<span>Email outreach tool</span>}>
			<div className={styles.body}>
				<div>
					<Text variant="inter/15/regular" component="p" lineHeight={160} className={styles.text}>
						1. Sign in to{' '}
						<a href="https://sourcewhale.app/" target="_blank" rel="noreferrer" className={styles.link}>
							SourceWhale
						</a>
					</Text>

					<Text variant="inter/15/regular" component="p" lineHeight={160} className={styles.text}>
						2. From the SourceWhale dashboard, click on your profile icon in the bottom left-hand corner and
						click <Text variant="inter/15/semi">Admin</Text>.
					</Text>

					<Text variant="inter/15/regular" component="p" lineHeight={160} className={styles.text}>
						3. Click on <Text variant="inter/15/semi">Settings</Text>, then click{' '}
						<Text variant="inter/15/semi">Generate API Key</Text>. The API Key will be generated for you to
						copy and paste into the integration settings of the other platform.
					</Text>
				</div>

				<FormikProvider value={formik}>
					<Form layout="vertical">
						<FormikInput name="key" label="API key" size="large" placeholder="Paste API key" />

						<div className={styles.actions}>
							<Button
								loading={formik.isSubmitting}
								disabled={formik.isSubmitting}
								onClick={formik.submitForm}
								type="primary">
								{isConnected ? 'Update' : 'Connect'}
							</Button>
						</div>
					</Form>
				</FormikProvider>
			</div>
		</IntegrationCard>
	);
};
