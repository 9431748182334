import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { antTheme } from 'configs';
import { store } from 'store/store';
import { Analytics } from 'features/analytics';
import { environment } from 'environments/environment';

import { Router } from './routes/Router';
import './style.scss';

Analytics.init();

export const App = () => {
	if (environment.env === 'production') {
		const loScript = document.createElement('script');
		loScript.src = 'https://tools.luckyorange.com/core/lo.js?site-id=54fbbbe0';
		loScript.async = true;
		loScript.defer = true;
		document.head.appendChild(loScript);
	}

	return (
		<Provider store={store}>
			<ConfigProvider theme={antTheme}>
				<GoogleReCaptchaProvider reCaptchaKey={environment.recaptchaKey}>
					<Router />
				</GoogleReCaptchaProvider>
			</ConfigProvider>
		</Provider>
	);
};
