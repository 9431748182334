import { FC, useEffect } from 'react';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Text } from 'shared/components/ui';
import { PROGAI_EMAIL } from 'shared/utils';
import { useLocalStorage } from 'shared/hooks';
import { useGetCurrentUserQuery } from 'services';

import { useIsRecruiter, useIsStartup } from '../../hooks';

import styles from './index.module.scss';

interface Props {
	onClose?: () => void;
}

export const OutOfCreditsModal: FC<Props> = ({ onClose }: Props) => {
	const { data } = useGetCurrentUserQuery();
	const isStartup = useIsStartup();
	const isRecruiter = useIsRecruiter();

	const [shouldShowCreditsModal, setShouldShowCreditsModal] = useLocalStorage('shouldShowCreditsModal', true);

	useEffect(() => {
		if (data && data.credits > 10) {
			setShouldShowCreditsModal(true);
		}
	}, [data]);

	const handleClose = () => {
		setShouldShowCreditsModal(false);
		onClose?.();
	};

	if ((isStartup || isRecruiter) && data && data.credits <= 10 && shouldShowCreditsModal)
		return (
			<CenterModal onClose={handleClose} icon="credits" iconColor="yellow" showClose>
				<div>
					<Text variant="inter/20/medium" color="dark_blue" component="h3" className={styles.title}>
						You are running out of credits
					</Text>
					<Text color="dark_blue" component="p">
						Contact us to get more credits
					</Text>
				</div>
				<ModalFooter>
					<Button component="a" href={`mailto:${PROGAI_EMAIL}`} type="primary">
						Contact us
					</Button>
				</ModalFooter>
			</CenterModal>
		);

	return null;
};
