import { FC } from 'react';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Icon, Text } from 'shared/components/ui';
import { Project } from 'shared/generated-models';
import { useDeleteProjectsMutation } from 'services';
import { isPlural } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
	onDelete: () => void;
	projects: Project[];
}

const VISIBLE_COUNT = 5;

export const DeleteProjectsModal: FC<Props> = ({ onClose, projects, onDelete }: Props) => {
	const [deleteProjects] = useDeleteProjectsMutation();

	const handleDelete = async () => {
		try {
			await deleteProjects({ ids: projects.map(project => project.id) }).unwrap();
			onDelete();
			onClose();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<CenterModal onClose={onClose} icon="trash" iconColor="red">
			<div className={styles.body}>
				<Text variant="inter/20/medium" color="dark_blue">
					Delete the following {projects.length > 1 ? 'projects' : 'project'}?
				</Text>
				<div className={styles.list}>
					{projects.map(project => (
						<div className={styles.listItem} key={project.id}>
							<Icon icon="folder" size={20} />
							<Text className={styles.listItemText}>{project.name}</Text>
						</div>
					))}
				</div>
				{projects.length > VISIBLE_COUNT && (
					<Text variant="inter/14/semi" color="dark_grey">
						{isPlural(projects.length) ? `${projects.length} projects` : `${projects.length} project`}
					</Text>
				)}
			</div>
			<ModalFooter>
				<Button type="ghost" onClick={onClose}>
					Cancel
				</Button>
				<Button type="danger" onClick={handleDelete}>
					Delete
				</Button>
			</ModalFooter>
		</CenterModal>
	);
};
