import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Skeleton } from 'antd';

import { SkeletonRow } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
}

export const ResultsSkeleton: FC<Props> = ({ className, children, ...otherProps }) => {
	return (
		<section {...otherProps} className={cn(styles.skeleton, className)}>
			{[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
				<div key={item} className={styles.skeletonRow}>
					<div className={styles.skeletonTop}>
						<div className={styles.skeletonInfo}>
							<div className={styles.skeletonCheckbox} />
							<Skeleton.Avatar size={60} shape="circle" active />
							<div className={styles.skeletonText}>
								<div className={styles.skeletonData}>
									<SkeletonRow width={101} />
									<SkeletonRow width={109} />
								</div>
								<SkeletonRow width={142} />
							</div>
						</div>
						<SkeletonRow width={46} className={styles.skeletonCell} />
						<SkeletonRow width={65} className={styles.skeletonCell} />
						<SkeletonRow width={83} className={styles.skeletonCell} />
					</div>
					<div className={styles.skeletonSkills}>
						<SkeletonRow width={105} height={34} />
						<SkeletonRow width={77} height={34} />
						<SkeletonRow width={77} height={34} />
						<SkeletonRow width={77} height={34} />
						<SkeletonRow width={77} height={34} />
					</div>
				</div>
			))}
		</section>
	);
};
