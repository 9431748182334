import { useNavigate } from 'react-router-dom';
import qs from 'query-string';

import { SearchParams } from 'shared/generated-models';
import { SEARCH_LOADING_SIZE } from 'features/search/models';
import { saveSearch } from 'features/search/utils/search-history';

export const useSearch = () => {
	const navigate = useNavigate();

	return (values: SearchParams, state?: any) => {
		const cleanParams = Object.entries(values)
			.filter(([_, v]) => (Array.isArray(v) ? v.length > 0 : v != null))
			.reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

		const search = qs.stringify(
			{ ...cleanParams, size: SEARCH_LOADING_SIZE },
			{
				arrayFormat: 'bracket-separator',
				arrayFormatSeparator: ','
			}
		);

		saveSearch(search, cleanParams);

		navigate(
			{
				pathname: '/search/results',
				search: search
			},
			{ state }
		);
	};
};
