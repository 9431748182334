export const formatLocation = (location?: string, country?: string): string => {
	if (location && country) {
		if (location.includes(country)) return location;
		return `${location}, ${country}`;
	}
	if (location) return location;
	if (country) return country;
	return '';
};

export const formatLocationShort = (location?: string, country?: string): string => {
	if (location && country) {
		if (location.includes(country)) return location;
		if (country === 'United States') return location;
		return `${location}, ${country}`;
	}
	if (location) return location;
	if (country) return country;
	return '';
};
