import React, { FC, useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';

import { CandidateHeader, CandidateProfile } from 'features/candidate/components';
import { ProgAIResults } from 'shared/generated-models';
import { useGetCandidateProfileQuery } from 'services';
import { Analytics } from 'features/analytics';
import { useAppDispatch } from 'store';
import { setOpenCandidateModal } from 'widgets/Header';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLazyGetVerifyTokenQuery } from 'services';

import styles from './index.module.scss';

interface Props {
	candidate: ProgAIResults;
	onClose: () => void;
	candidates: ProgAIResults[];
	setCandidate: (candidate: ProgAIResults | null) => void;
	total?: number;
	page: number;
	onChange: (page: number) => void;
}

export const CandidateModal: FC<Props> = ({
	candidates,
	candidate,
	setCandidate,
	onClose,
	total,
	page,
	onChange
}: Props) => {
	const { data, isFetching } = useGetCandidateProfileQuery(candidate.profile.id);
	const dispatch = useAppDispatch();

	const [verfiyToken] = useLazyGetVerifyTokenQuery();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available');
			return;
		}

		const token = await executeRecaptcha('Candidate');
		verfiyToken(token);
	}, [executeRecaptcha]);

	useEffect(() => {
		Analytics.trackProfileOpening(candidate.profile.id);
		dispatch(setOpenCandidateModal(true));

		return () => {
			dispatch(setOpenCandidateModal(false));
		};
	}, []);

	useEffect(() => {
		handleReCaptchaVerify();
	}, [handleReCaptchaVerify, candidate.profile.id]);

	return (
		<Modal
			open={true}
			destroyOnClose={true}
			onCancel={onClose}
			footer={null}
			closable={false}
			className={styles.modal}
			transitionName="">
			<CandidateHeader
				variant="search"
				data={data}
				onClose={onClose}
				candidates={candidates}
				setCandidate={setCandidate}
				total={total}
				page={page}
				onChange={onChange}
			/>
			<CandidateProfile variant="search" candidate={candidate} data={data} isFetching={isFetching} />
		</Modal>
	);
};
