import React, { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';
import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import { authLink } from 'services';
import { parseJwt } from 'shared/utils';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const LoginForm: FC<Props> = ({ className }: Props) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const accessToken = searchParams.get('access');
	const refreshToken = searchParams.get('refresh');
	const isNew = searchParams.get('is_new');

	const submitForm = () => {
		window.location.href = authLink;
	};

	useEffect(() => {
		if (accessToken && refreshToken) {
			dispatch(setCredentials({ accessToken, refreshToken }));

			const parsed = parseJwt(accessToken);

			Analytics.trackLogin({
				isNew: isNew === 'True',
				id: parsed.user_id.toString(),
				billingPlan: parsed.billing_plan
			});

			navigate(isNew === 'False' ? '/' : '/registration/form', {
				replace: true
			});
		}
	}, [accessToken, dispatch, isNew, navigate, refreshToken]);

	const handleClick = () => {
		window.open('https://www.getprog.ai/', '_blank');
	};

	return (
		<div className={cn(styles.login, className)}>
			<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
			<div className={styles.card}>
				<div className={styles.cardTop}>
					<Text variant="inter/32/semi">Try Prog.AI for free. Upgrade later</Text>
					<Text variant="inter/16/regular" component="p">
						14-day free trial. No card required
					</Text>
					<ul>
						<li>
							<Text variant="inter/16/regular" component="p">
								Access to over 60M candidate profiles
							</Text>
						</li>
						<li>
							<Text variant="inter/16/regular" component="p">
								AI search leveraging 16 search filters including programming languages, tenure,
								diversity, language, education, etc.
							</Text>
						</li>
						<li>
							<Text variant="inter/16/regular" component="p">
								Likely-to-Move<span style={{ fontSize: '0.6em', verticalAlign: 'super' }}>TM</span>{' '}
								probability: a proprietary algorithm predicting job change likelihood in candidates
							</Text>
						</li>
					</ul>
				</div>
				<div>
					<button className={styles.loginButton} onClick={submitForm}>
						<Icon className={styles.loginButtonIcon} icon="google-logo" size={32} />
						<Text variant="inter/16/medium" color="white">
							Sign up free with Google
						</Text>
					</button>
					<Text variant="inter/16/regular" className={styles.signIn} color="grey">
						Already have an account?{' '}
						<button onClick={submitForm}>
							<span>Sign In</span>
						</button>
					</Text>
				</div>
			</div>
			<Text variant="grotesk/12/regular" lineHeight={106} component="p" color="grey" className={styles.loginHint}>
				By&nbsp;proceeding, you agree to&nbsp;our{' '}
				<a href="https://getprog.ai/terms-conditions" target="_blank" rel="noreferrer">
					Terms of&nbsp;Use
				</a>{' '}
				and confirm you have read our{' '}
				<a href="https://getprog.ai/privacy-policy" target="_blank" rel="noreferrer">
					Privacy and Cookies Statement
				</a>
				.
			</Text>
		</div>
	);
};
