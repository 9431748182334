import mixpanel from 'mixpanel-browser';

import { environment } from 'environments/environment';
import { BillingData, User, ProgAISearchResult, ServiceEnum } from 'shared/generated-models';

import { AnalyticsEvents, AnalyticsProperties } from './models';

export class Analytics {
	static init() {
		mixpanel.init(environment.mixpanelToken, { ignore_dnt: true });
	}

	static trackSearch(searchResult: ProgAISearchResult) {
		const { results_count, request_fields } = searchResult;

		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_SEARCHES);

		if (request_fields) {
			const metadata = {
				[AnalyticsProperties.CANDIDATES_COUNT]: results_count,
				[AnalyticsProperties.REQUIRED]: !!request_fields.required_skills,
				[AnalyticsProperties.BONUS]: !!request_fields.bonus_skills,
				[AnalyticsProperties.TITLE]: !!request_fields.job_titles,
				[AnalyticsProperties.EMPLOYMENT]: !!request_fields.yo_employment,
				[AnalyticsProperties.EXPERIENCE]: !!request_fields.yo_experience,
				[AnalyticsProperties.SENIORITY]: !!request_fields.seniority,
				[AnalyticsProperties.LOCATION]: !!request_fields.locations,
				[AnalyticsProperties.COUNTRY]: !!request_fields.countries,
				[AnalyticsProperties.REGION]: !!request_fields.regions,
				[AnalyticsProperties.SUBREGION]: !!request_fields.sub_regions,
				[AnalyticsProperties.COMPANIES]: !!request_fields.companies,
				[AnalyticsProperties.EXCLUDED]: !!request_fields.companies_to_exclude,
				[AnalyticsProperties.REPOS]: !!request_fields.github_repos,
				[AnalyticsProperties.LANGUAGES]: !!request_fields.languages,
				[AnalyticsProperties.PROGRAMMING_LANGUAGES]: !!request_fields.prog_langs,
				[AnalyticsProperties.TOP_SCHOOLS]: !!request_fields.top_schools,
				[AnalyticsProperties.IS_TOP_SCHOOLS]: !!request_fields.is_top_school,
				[AnalyticsProperties.IS_FEMALE]: !!request_fields.is_first_name_female,
				[AnalyticsProperties.LIKELY_TO_MOVE]: !!request_fields.likely_to_move_prob,
				[AnalyticsProperties.PAGE_NUMBER]: request_fields.page || 0
			};

			mixpanel.track(AnalyticsEvents.SEARCH, metadata);
			window.Intercom('trackEvent', AnalyticsEvents.SEARCH, metadata);
		} else {
			window.Intercom('trackEvent', AnalyticsEvents.SEARCH, {
				[AnalyticsProperties.CANDIDATES_COUNT]: results_count
			});
		}
	}

	static trackProfileOpening(id: number) {
		const metadata = { [AnalyticsProperties.PROFILE_ID]: id };

		mixpanel.track(AnalyticsEvents.OPEN_PROFILE, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_PROFILE_OPENS);

		window.Intercom('trackEvent', AnalyticsEvents.OPEN_PROFILE, metadata);
	}

	static trackLogin({ isNew, id, billingPlan }: { isNew: boolean; id: string; billingPlan: string }) {
		if (isNew) {
			mixpanel.alias(id);
		} else {
			mixpanel.identify(id);
		}

		const lastLogin = new Date().toISOString();

		mixpanel.people.set({
			[AnalyticsProperties.LAST_LOGIN]: lastLogin
		});
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_LOGINS);
		mixpanel.register({ [AnalyticsProperties.BILLING_PLAN]: billingPlan });

		window.Intercom('trackEvent', AnalyticsEvents.LOGIN, {
			[AnalyticsProperties.BILLING_PLAN]: billingPlan,
			[AnalyticsProperties.LAST_LOGIN]: lastLogin
		});
	}

	static trackRegistration(user: User) {
		mixpanel.register({
			[AnalyticsProperties.FIRST_NAME]: user.first_name,
			[AnalyticsProperties.LAST_NAME]: user.last_name,
			[AnalyticsProperties.BILLING_PLAN]: user.billing_plan
		});

		mixpanel.people.set({
			[AnalyticsProperties.REGISTRATION_DATE]: new Date().toISOString(),
			[AnalyticsProperties.MIXPANEL_FIRST_NAME]: user.first_name,
			[AnalyticsProperties.MIXPANEL_LAST_NAME]: user.last_name,
			[AnalyticsProperties.MIXPANEL_NAME]: `${user.first_name} ${user.last_name}`,
			[AnalyticsProperties.MIXPANEL_EMAIL]: user.email,
			[AnalyticsProperties.COMPANY]: user.company
		});

		const metadata = {
			[AnalyticsProperties.FIRST_NAME]: user.first_name,
			[AnalyticsProperties.LAST_NAME]: user.last_name,
			[AnalyticsProperties.BILLING_PLAN]: user.billing_plan,
			[AnalyticsProperties.REGISTRATION_DATE]: new Date().toISOString(),
			[AnalyticsProperties.EMAIL]: user.email,
			[AnalyticsProperties.COMPANY]: user.company
		};

		mixpanel.track(AnalyticsEvents.REGISTRATION, metadata);

		window.Intercom('trackEvent', AnalyticsEvents.REGISTRATION, metadata);
	}

	static trackUpgradeClicked(user: User) {
		const metadata = {
			[AnalyticsProperties.EMAIL]: user.email,
		};

		mixpanel.track(AnalyticsEvents.UPGRADE_CLICKED, metadata);

		window.Intercom('trackEvent', AnalyticsEvents.UPGRADE_CLICKED, metadata);
	}

	static trackTopExpertsClicked(user: User) {
		const metadata = {
			[AnalyticsProperties.EMAIL]: user.email,
		};

		mixpanel.track(AnalyticsEvents.TOP_EXPERTS_CLICKED, metadata);

		window.Intercom('trackEvent', AnalyticsEvents.TOP_EXPERTS_CLICKED, metadata);
	}

	static trackChromeExtensionClicked(user: User) {
		const metadata = {
			[AnalyticsProperties.EMAIL]: user.email,
		};

		mixpanel.track(AnalyticsEvents.CHROME_EXTENSION_CLICKED, metadata);

		window.Intercom('trackEvent', AnalyticsEvents.CHROME_EXTENSION_CLICKED, metadata);
	}

	static trackProfileShare(id: number) {
		const metadata = { [AnalyticsProperties.PROFILE_ID]: id };

		mixpanel.track(AnalyticsEvents.SHARE_PROFILE, { [AnalyticsProperties.PROFILE_ID]: id });
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_SHARINGS);

		window.Intercom('trackEvent', AnalyticsEvents.SHARE_PROFILE, metadata);
	}

	static trackSharedOpening(id: number) {
		const metadata = { [AnalyticsProperties.PROFILE_ID]: id };

		mixpanel.track(AnalyticsEvents.OPEN_SHARED_PROFILE, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_SHARED_PROFILE_OPENS);

		window.Intercom('trackEvent', AnalyticsEvents.OPEN_SHARED_PROFILE, metadata);
	}

	static trackCsvExport(count: number, source: 'Search' | 'Project' | 'Contacts') {
		const metadata = {
			[AnalyticsProperties.NUMBER_OF_EXPORTED_PROFILES]: count,
			[AnalyticsProperties.SOURCE]: source
		};

		mixpanel.track(AnalyticsEvents.EXPORT, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_EXPORTS);

		window.Intercom('trackEvent', AnalyticsEvents.EXPORT, metadata);
	}

	static trackGoogleSheetsExport(count: number, source: 'Search' | 'Project' | 'Contacts') {
		const metadata = {
			[AnalyticsProperties.NUMBER_OF_EXPORTED_PROFILES]: count,
			[AnalyticsProperties.SOURCE]: source
		};

		mixpanel.track(AnalyticsEvents.EXPORT_GOOGLE_SHEETS, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_EXPORTS);

		window.Intercom('trackEvent', AnalyticsEvents.EXPORT_GOOGLE_SHEETS, metadata);
	}

	static trackLinkedinClick(id: number, link: string) {
		const metadata = {
			[AnalyticsProperties.PROFILE_ID]: id,
			[AnalyticsProperties.SOURCE]: 'Linkedin',
			[AnalyticsProperties.LINK]: link
		};

		mixpanel.track(AnalyticsEvents.CLICKED_ON_LINKEDIN, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REDIRECTS);

		window.Intercom('trackEvent', AnalyticsEvents.CLICKED_ON_LINKEDIN, metadata);
	}

	static trackGithubClick(id: number, link: string) {
		const metadata = {
			[AnalyticsProperties.PROFILE_ID]: id,
			[AnalyticsProperties.SOURCE]: 'Github',
			[AnalyticsProperties.LINK]: link
		};

		mixpanel.track(AnalyticsEvents.CLICKED_ON_GITHUB, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REDIRECTS);

		window.Intercom('trackEvent', AnalyticsEvents.CLICKED_ON_GITHUB, metadata);
	}

	static trackTwitterClick(id: number, link: string) {
		const metadata = {
			[AnalyticsProperties.PROFILE_ID]: id,
			[AnalyticsProperties.SOURCE]: 'Twitter',
			[AnalyticsProperties.LINK]: link
		};

		mixpanel.track(AnalyticsEvents.CLICKED_ON_TWITTER, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REDIRECTS);

		window.Intercom('trackEvent', AnalyticsEvents.CLICKED_ON_TWITTER, metadata);
	}

	static trackFacebookClick(id: number, link: string) {
		const metadata = {
			[AnalyticsProperties.PROFILE_ID]: id,
			[AnalyticsProperties.SOURCE]: 'Facebook',
			[AnalyticsProperties.LINK]: link
		};

		mixpanel.track(AnalyticsEvents.CLICKED_ON_FACEBOOK, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REDIRECTS);

		window.Intercom('trackEvent', AnalyticsEvents.CLICKED_ON_FACEBOOK, metadata);
	}

	static trackStackoverflowClick(id: number, link: string) {
		const metadata = {
			[AnalyticsProperties.PROFILE_ID]: id,
			[AnalyticsProperties.SOURCE]: 'Stackoverflow',
			[AnalyticsProperties.LINK]: link
		};

		mixpanel.track(AnalyticsEvents.CLICKED_ON_STACKOVERFLOW, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REDIRECTS);

		window.Intercom('trackEvent', AnalyticsEvents.CLICKED_ON_STACKOVERFLOW, metadata);
	}

	static trackHomepageClick(id: number, link: string) {
		const metadata = {
			[AnalyticsProperties.PROFILE_ID]: id,
			[AnalyticsProperties.SOURCE]: 'Homepage',
			[AnalyticsProperties.LINK]: link
		};

		mixpanel.track(AnalyticsEvents.CLICKED_ON_HOMEPAGE, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REDIRECTS);

		window.Intercom('trackEvent', AnalyticsEvents.CLICKED_ON_HOMEPAGE, metadata);
	}

	static trackPaymentSuccess(billingData: BillingData) {
		const metadata = {
			[AnalyticsProperties.BILLING_PLAN]: billingData.billing_plan,
			[AnalyticsProperties.INTERVAL]: billingData.interval
		};

		mixpanel.register(metadata);
		mixpanel.track(AnalyticsEvents.SUBSCRIPTION, metadata);

		window.Intercom('trackEvent', AnalyticsEvents.SUBSCRIPTION, metadata);
	}

	static trackGetContact(id: number) {
		const metadata = {
			[AnalyticsProperties.PROFILE_ID]: id
		};

		mixpanel.track(AnalyticsEvents.GET_CONTACT, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REVEALS);

		window.Intercom('trackEvent', AnalyticsEvents.GET_CONTACT, metadata);
	}

	static trackGetContacts(ids: number[]) {
		const metadata = {
			[AnalyticsProperties.PROFILE_IDS]: ids
		};

		mixpanel.track(AnalyticsEvents.GET_CONTACT, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REVEALS);

		window.Intercom('trackEvent', AnalyticsEvents.GET_CONTACT, metadata);
	}

	static trackPhoneCopy(id: number, phone: string) {
		const metadata = {
			[AnalyticsProperties.PROFILE_ID]: id,
			[AnalyticsProperties.SOURCE]: 'Phone',
			[AnalyticsProperties.LINK]: phone
		};

		mixpanel.track(AnalyticsEvents.COPIED_PHONE, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_COPIES);

		window.Intercom('trackEvent', AnalyticsEvents.COPIED_PHONE, metadata);
	}

	static trackEmailCopy(id: number, email: string) {
		const metadata = {
			[AnalyticsProperties.PROFILE_ID]: id,
			[AnalyticsProperties.SOURCE]: 'Email',
			[AnalyticsProperties.LINK]: email
		};

		mixpanel.track(AnalyticsEvents.COPIED_EMAIL, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_COPIES);

		window.Intercom('trackEvent', AnalyticsEvents.COPIED_EMAIL, metadata);
	}

	static trackAddingCandidatesToProject({
		projectId,
		candidatesCount
	}: {
		projectId?: number;
		candidatesCount?: number;
	}) {
		const metadata = {
			[AnalyticsProperties.PROJECT_ID]: projectId,
			[AnalyticsProperties.NUMBER_OF_CANDIDATES]: candidatesCount
		};

		mixpanel.track(AnalyticsEvents.ADD_TO_PROJECT, metadata);
		window.Intercom('trackEvent', AnalyticsEvents.ADD_TO_PROJECT, metadata);
	}

	static trackPushToOutreach({ service, candidatesCount }: { service: ServiceEnum; candidatesCount: number }) {
		const metadata = {
			[AnalyticsProperties.SERVICE]: service,
			[AnalyticsProperties.NUMBER_OF_CANDIDATES]: candidatesCount
		};

		mixpanel.track(AnalyticsEvents.PUSH_TO_OUTREACH, metadata);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_PUSHED, candidatesCount);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_PUSHES);

		window.Intercom('trackEvent', AnalyticsEvents.PUSH_TO_OUTREACH, metadata);
	}

	static trackParsedText() {
		mixpanel.track(AnalyticsEvents.PARSED_TEXT);
		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_PARSED);

		window.Intercom('trackEvent', AnalyticsEvents.PARSED_TEXT);
	}
}
