import { Avatar } from 'antd';

import IterativeLogo from 'shared/assets/images/iterative-logo.webp';
import IvanShckeklein from 'shared/assets/images/IvanShcheklein.webp';
import MutableaiLogo from 'shared/assets/images/mutableai-logo.webp';
import OmarShams from 'shared/assets/images/OmarShams.webp';
import AklivityLogo from 'shared/assets/images/aklivity-logo.webp';
import LeonidLukyanov from 'shared/assets/images/LeonidLukyanov.webp';
import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

export const SubscriptionTestimonials = () => {
	return (
		<section>
			<Text component="h2" variant="grotesk/32/bold" lineHeight={106} className={styles.title}>
				What our customers say
			</Text>
			<div className={styles.cards}>
				<article className={styles.card}>
					<img src={IterativeLogo} alt="iterative logo" className={styles.cardLogo} />
					<Text variant="grotesk/16/regular" lineHeight={140}>
						&ldquo;Definitely much more targeted candidates for what we&nbsp;are looking for &rdquo;
					</Text>
					<div className={styles.cardInfo}>
						<Avatar className={styles.cardAvatar} src={IvanShckeklein} shape="square" size={56} />
						<div>
							<Text variant="mono/16/bold" lineHeight={140}>
								Ivan Shcheklein
							</Text>
							<Text variant="grotesk/16/regular" lineHeight={140} color="grey">
								CTO and co-founder
							</Text>
						</div>
					</div>
				</article>
				<article className={styles.card}>
					<img src={MutableaiLogo} alt="mutableai logo" className={styles.cardLogo} />
					<Text variant="grotesk/16/regular" lineHeight={140}>
						&ldquo;I&nbsp;was looking to&nbsp;hire a&nbsp;founding front-end engineer with&nbsp;ML
						background and ProgAI has generated a&nbsp;perfectly targeted list
						of&nbsp;1,000&nbsp;candidates.&rdquo;
					</Text>
					<div className={styles.cardInfo}>
						<Avatar className={styles.cardAvatar} src={OmarShams} shape="square" size={56} />
						<div>
							<Text variant="mono/16/bold" lineHeight={140}>
								Omar Shams
							</Text>
							<Text variant="grotesk/16/regular" lineHeight={140} color="grey">
								CEO and founder
							</Text>
						</div>
					</div>
				</article>
				<article className={styles.card}>
					<img src={AklivityLogo} alt="alkivity logo" className={styles.cardLogo} />
					<Text variant="grotesk/16/regular" lineHeight={140}>
						&ldquo;We&rsquo;ve got 12&nbsp;super relevant candidates to&nbsp;interview within the first two
						weeks of&nbsp;working with ProgAI!&rdquo;
					</Text>
					<div className={styles.cardInfo}>
						<Avatar className={styles.cardAvatar} src={LeonidLukyanov} shape="square" size={56} />
						<div>
							<Text variant="mono/16/bold" lineHeight={140}>
								Leonid Lukyanov
							</Text>
							<Text variant="grotesk/16/regular" lineHeight={140} color="grey">
								CEO and co-founder
							</Text>
						</div>
					</div>
				</article>
			</div>
		</section>
	);
};
