import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import { SearchParams, SearchSkills, ProgAISearchResult } from 'shared/generated-models';

export const searchService = createApi({
	reducerPath: 'searchService',
	baseQuery: interceptor,
	tagTypes: ['Search'],
	endpoints: builder => ({
		getCandidates: builder.query<ProgAISearchResult, SearchParams>({
			query: params => ({
				url: '/search/',
				method: 'POST',
				body: params,
				invalidatesTags: ['Search']
			})
		}),
		uploadText: builder.mutation<SearchSkills, { text: string }>({
			query: params => ({
				url: '/upload_text/',
				method: 'POST',
				body: params
			})
		}),
		uploadFile: builder.mutation<SearchSkills, Blob>({
			query: file => {
				const data = new FormData();
				data.append('file', file);

				return {
					url: '/upload_file/',
					method: 'POST',
					body: data
				};
			}
		})
	})
});

export const { useGetCandidatesQuery, useUploadTextMutation, useUploadFileMutation, usePrefetch } = searchService;
