import { FC } from 'react';
import cn from 'classnames';

import { OpenedContactShort, ProgAIProfile } from 'shared/generated-models';
import { Icon, Text, TextVariant } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	profile: ProgAIProfile | OpenedContactShort;
	className?: string;
	variant?: TextVariant;
}

export const ProfileName: FC<Props> = ({ profile, className, variant }: Props) => {
	if (profile.first_name || profile.last_name) {
		const first_name = profile.first_name ? profile.first_name : '';
		const last_name = profile.last_name ? profile.last_name : '';
		return (
			<Text variant={variant} className={className} paragraph>
				{`${first_name} ${last_name}`.replaceAll(' ', '\xA0')}
			</Text>
		);
	}

	return (
		<div className={cn(styles.github, className)}>
			<Text variant={variant} paragraph>
				{profile.github_handle}
			</Text>
			<Icon icon="github-logo" size={20} className={styles.githubLogo} />
		</div>
	);
};
