import { useEffect, useState } from 'react';
import { ConfigProvider, Tooltip } from 'antd';
import cn from 'classnames';

import { TextButton, Text, Icon } from 'shared/components/ui';
import { useGetSearchHistory } from 'features/search/hooks';
import { AntPagination } from 'features/search/components';

import styles from './index.module.scss';

type item = {
	time: string;
	link: string;
	text: string;
	hint: string;
};

type section = {
	header: string;
	items: item[];
};

type props = {
	section: section;
	containsDate: boolean;
};

type cardProps = {
	isOpen: boolean;
	handleClick: () => void;
};

const SearchHistorySection = ({ section, containsDate }: props) => {
	const tooltipPlacement = 'right';

	return (
		<div className={styles.section}>
			<Text className={styles.sectionHeader} variant="inter/16/medium" color="grey">
				{section.header}
			</Text>
			{section.items.map(item => (
				<div className={styles.sectionBody} key={item.link}>
					<Text className={containsDate ? styles.date : styles.time} variant="inter/16/regular" color="grey">
						{item.time}
					</Text>
					<ConfigProvider
						theme={{
							components: {
								Tooltip: {
									paddingSM: 0,
									paddingXS: 0,
									borderRadius: 2,
									colorBgDefault: '#f8f8f8'
								}
							}
						}}>
						<Tooltip
							arrow={false}
							title={
								<div className={styles.tooltip}>
									<Text color="dark_green">{item.hint}</Text>
								</div>
							}
							color="white"
							placement={tooltipPlacement}
							overlayClassName={styles.labelTooltip}>
							<a
								href={`/search/results?${item.link}`}
								target="_blank"
								rel="noreferrer"
								className={styles.link}>
								<Text className={styles.text} variant="inter/16/regular">
									{item.text}
								</Text>
							</a>
						</Tooltip>
					</ConfigProvider>
				</div>
			))}
		</div>
	);
};

const RecentSearchCard = ({ isOpen, handleClick }: cardProps) => {
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(10);

	useEffect(() => {
		if (!isOpen) {
			setPage(1);
			setSize(5);
		} else {
			setPage(1);
			setSize(10);
		}
	}, [isOpen]);

	const { searchHistory, itemsMaxLength, containsDate } = useGetSearchHistory(page, size);

	const handleOnChange = (page: number) => {
		setPage(page);
	};

	if (searchHistory.length === 0) {
		return (
			<div className={cn(styles.card, styles.cardEmpty)}>
				<div className={styles.emptyHistory}>
					<Icon className={styles.icon} icon={'empty-folder'} />
					<Text variant="inter/16/regular" color="grey">
						No searches yet
					</Text>
				</div>
			</div>
		);
	}

	return (
		<div
			className={cn(styles.card, {
				[styles.cardOpen]: isOpen
			})}>
			<div
				className={cn({
					[styles.items]: !isOpen
				})}>
				{searchHistory.map((section: section) => (
					<SearchHistorySection section={section} containsDate={containsDate} key={section.header} />
				))}
			</div>
			{itemsMaxLength > 5 && (
				<>
					{isOpen && itemsMaxLength > 10 && (
						<AntPagination total={itemsMaxLength} handleOnChange={handleOnChange} />
					)}
					<div className={styles.seeMore}>
						<Text className={styles.seeMoreText} variant="inter/14/regular" color="grey">
							<TextButton onClick={handleClick}>
								{isOpen ? 'See less' : `See all (${Math.min(itemsMaxLength, 10)})`}
							</TextButton>
						</Text>
					</div>
				</>
			)}
		</div>
	);
};

export const RecentSearch = () => {
	const [isOpen, setIsOpen] = useState(false);
	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				<Icon className={styles.icon} icon={'history'} size={24} />
				<Text variant="inter/16/regular" color="grey">
					Search history
				</Text>
			</div>
			<RecentSearchCard isOpen={isOpen} handleClick={handleClick} />
		</div>
	);
};
