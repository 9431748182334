import IterativeLogo from 'shared/assets/images/iterative-logo.webp';
import BardeenLogo from 'shared/assets/images/bardeen-logo.webp';
import ActiveloopLogo from 'shared/assets/images/activeloop-logo.webp';
import AklivityLogo from 'shared/assets/images/aklivity-logo.webp';
import MutableaiLogo from 'shared/assets/images/mutableai-logo.webp';
import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

export const SubscriptionTrusted = () => {
	return (
		<section>
			<Text component="h2" variant="grotesk/20/regular" className={styles.title}>
				Trusted by
			</Text>
			<section className={styles.companies}>
				<img className={styles.company} src={IterativeLogo} alt="iterative logo" />
				<img className={styles.company} src={BardeenLogo} alt="bardeen logo" />
				<img className={styles.company} src={ActiveloopLogo} alt="activeloop logo" />
				<img className={styles.company} src={AklivityLogo} alt="aklivity logo" />
				<img className={styles.company} src={MutableaiLogo} alt="mutableai logo" />
			</section>
		</section>
	);
};
