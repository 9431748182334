import { Accordion, Text } from 'shared/components/ui';
import { questions } from 'features/subscription/utils';
import { PROGAI_EMAIL } from 'shared/utils';

import styles from './index.module.scss';

export const SubscriptionQA = () => {
	return (
		<section>
			<Text component="h2" variant="grotesk/32/bold" lineHeight={106} className={styles.title}>
				Questions &amp;&nbsp;Answers
			</Text>
			<div className={styles.questions}>
				{questions.map((question, idx) => (
					<Accordion
						className={styles.question}
						key={idx}
						title={
							<Text component="h3" variant="grotesk/20/bold">
								{question.question}
							</Text>
						}
						content={<Text variant="grotesk/16/regular">{question.answer}</Text>}
					/>
				))}
			</div>
			<Text component="p" variant="grotesk/16/regular" color="grey" className={styles.contact}>
				Can&rsquo;t find the answer here? <a href={`mailto:${PROGAI_EMAIL}`}>Contact&nbsp;us</a>
			</Text>
		</section>
	);
};
