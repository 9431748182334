import React, { FC, KeyboardEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import cn from 'classnames';

import { useGetProjectQuery, useUpdateProjectMutation } from 'services';
import { Icon, Text } from 'shared/components/ui';
import { InlineInput } from 'shared/components';
import { createProjectFormValidationSchema, EmptyProjectRequest } from 'features/projects';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const ProjectName: FC<Props> = ({ className, ...otherProps }) => {
	const [isEditing, setIsEditing] = useState(false);
	const { id } = useParams();
	const { data } = useGetProjectQuery(Number(id));
	const [updateProject] = useUpdateProjectMutation();

	const handleSubmit = async (values: EmptyProjectRequest) => {
		if (!data) return;
		try {
			await updateProject({ ...data, name: values.name }).unwrap();
			setIsEditing(false);
		} catch (e: any) {
			if (e.data && e.data.name) {
				formik.setErrors({ name: 'Project with this name already exists' });
				return;
			}
			console.error(e);
		}
	};

	const formik = useFormik<EmptyProjectRequest>({
		validationSchema: createProjectFormValidationSchema,
		enableReinitialize: true,
		initialValues: { name: data?.name || '' },
		onSubmit: values => {
			if (!formik.dirty) {
				setIsEditing(false);
				return;
			}

			if (formik.isValid && formik.dirty) {
				handleSubmit(values);
			}
		}
	});

	const startEditing = () => {
		setIsEditing(true);
	};

	const handleEnterPress = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter') formik.handleSubmit();
	};

	if (!data) return null;

	return (
		<div {...otherProps} className={cn(styles.name, className)}>
			{isEditing ? (
				<FormikProvider value={formik}>
					<Field
						name="name"
						onKeyDown={handleEnterPress}
						className={styles.editInput}
						component={InlineInput}
						onBlur={formik.handleSubmit}
					/>
				</FormikProvider>
			) : (
				<button onClick={startEditing} className={styles.edit}>
					<Text variant="inter/14/bold" className={styles.editText}>
						{data.name}
					</Text>
					<Icon icon="pencil" size={16} className={styles.editIcon} />
				</button>
			)}
		</div>
	);
};
