import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import { ProgAICandidate } from 'shared/generated-models';
import { ProjectCandidateRequest } from 'shared/models';
import { authService, projectsService } from 'services';
import { voidFn } from 'shared/utils';

export type ProfileLink = {
	link: string;
};

export const CONTACTS_KEY = 'open-contacts';

export const profileService = createApi({
	reducerPath: 'profileService',
	tagTypes: ['Profile', 'Candidate'],
	baseQuery: interceptor,
	endpoints: builder => ({
		getCandidateProfile: builder.query<ProgAICandidate['profile'], number>({
			query: id => `/candidates/${id}/`,
			providesTags: ['Profile'],
			transformResponse(res: ProgAICandidate) {
				return res.profile;
			}
		}),
		getProjectCandidate: builder.query<any, ProjectCandidateRequest>({
			query: ({ project_pk, id }) => `/projects/${project_pk}/candidates/${id}/`,
			providesTags: ['Candidate']
		}),
		openCandidateContacts: builder.mutation<ProgAICandidate['profile'], number>({
			query: id => `/candidates/${id}/contacts/`,
			transformResponse(res: ProgAICandidate) {
				return res.profile;
			},
			invalidatesTags: ['Profile'],
			async onQueryStarted(csv, { dispatch, queryFulfilled }) {
				try {
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: Math.max(user.credits - 1, 0) };
						})
					);

					await queryFulfilled;
					dispatch(projectsService.util.invalidateTags(['OpenedContacts']));
				} catch (err) {
					console.error(err);
				}
			}
		}),
		openProjectContacts: builder.mutation<ProgAICandidate['profile'], number>({
			query: id => `/candidates/${id}/contacts/`,
			transformResponse(res: ProgAICandidate) {
				return res.profile;
			},
			invalidatesTags: ['Candidate'],
			async onQueryStarted(csv, { dispatch, queryFulfilled }) {
				try {
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: Math.max(user.credits - 1, 0) };
						})
					);

					await queryFulfilled;
					dispatch(projectsService.util.invalidateTags(['OpenedContacts']));
				} catch (err) {
					console.error(err);
				}
			}
		}),
		openMultipleCandidateContacts: builder.mutation<Array<ProgAICandidate['profile']>, Array<number>>({
			query: progAiIds => `/candidates/bulk_contacts/?prog_ai_ids=${progAiIds.join(',')}`,
			invalidatesTags: ['Candidate'],
			async onQueryStarted(progAiIds, { dispatch, queryFulfilled }) {
				try {
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: Math.max(user.credits - progAiIds.length, 0) };
						})
					);

					await queryFulfilled;
					dispatch(projectsService.util.invalidateTags(['OpenedContacts']));
				} catch (err) {
					console.error(err);
				}
			}
		}),
		getCandidateProfileLink: builder.query<ProfileLink, number>({
			query: id => ({
				url: `/candidates/${id}/create_link/`,
				method: 'POST',
				body: { id }
			})
		}),
		getVerifyToken: builder.query<void, string>({
			query: token => ({
				url: `/candidates/verify_recaptcha?token=${token}`,
				method: 'GET',
			})
		})
	})
});

export const {
	useGetCandidateProfileQuery,
	useLazyGetCandidateProfileLinkQuery,
	useOpenCandidateContactsMutation,
	useOpenProjectContactsMutation,
	useOpenMultipleCandidateContactsMutation,
	useGetProjectCandidateQuery,
	useLazyGetVerifyTokenQuery,
} = profileService;
