import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfigProvider, Tooltip } from 'antd';

import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	isAdvanced?: boolean;
	children: ReactNode;
}

export const AdvancedTooltip = ({ isAdvanced, children }: Props): JSX.Element => {
	const navigate = useNavigate();

	const handleUpgrade = () => {
		navigate('/registration/plan');
	};

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<Tooltip
				arrow={false}
				title={
					!isAdvanced ? (
						<div className={styles.tooltip}>
							<Text variant="inter/14/regular" color="dark_green">
								Show candidates who are more likely to respond to new job opportunities.
							</Text>
							<div className={styles.tooltipPlan}>
								<Text variant="inter/14/regular" color="dark_green">
									This filter is available for <Text variant="inter/14/semi">Recruiter Plan.</Text>
								</Text>
							</div>
							<div onClick={handleUpgrade}>
								<Text variant="inter/14/regular" className={styles.tooltipUpgrade}>
									Upgrade
								</Text>
							</div>
						</div>
					) : null
				}
				placement="right"
				overlayClassName={styles.tooltipWraper}>
				{children}
			</Tooltip>
		</ConfigProvider>
	);
};
