import { useMemo, useState } from 'react';
import cn from 'classnames';

import Check from 'shared/assets/svg-icons/check-gradient.svg';
import { Button, GradientBox, Icon, Text } from 'shared/components/ui';
import { Tabs } from 'shared/components/Tabs';
import { IntervalEnum, Plan } from 'shared/generated-models';
import { currencyFormatter, PROGAI_EMAIL } from 'shared/utils';
import { BillingPlan } from 'shared/models';
import { useGetCurrentUserQuery, useGetPlansQuery } from 'services';
import { CardButton, CardSkeleton } from 'features/subscription';

import styles from './index.module.scss';

export const SubscriptionCards = () => {
	const { data, isFetching } = useGetPlansQuery();
	const { data: user } = useGetCurrentUserQuery();

	const [interval, setInterval] = useState(IntervalEnum.YEAR);

	const [annually, monthly, trial] = useMemo(() => {
		if (data) {
			const annually: Plan[] = [];
			const monthly: Plan[] = [];
			let trial: Plan | null = null;

			data.forEach(item => {
				if (item.interval === IntervalEnum.YEAR) {
					if (item.billing_plan === BillingPlan.STARTUP && item.amount !== 79000) return;
					annually.push(item);
				} else if (item.interval === IntervalEnum.MONTH) {
					monthly.push(item);
				} else if (item.billing_plan === BillingPlan.FREE) {
					trial = item;
				}
			});

			return [annually, monthly, trial as any as Plan];
		} else {
			return [[], [], null];
		}
	}, [data]);

	const plans: Record<IntervalEnum, Plan[]> = {
		[IntervalEnum.YEAR]: annually,
		[IntervalEnum.MONTH]: monthly,
		[IntervalEnum.WEEKS]: monthly // TODO: this is stumb
	};

	if (isFetching)
		return (
			<section className={styles.cards}>
				{[0, 1, 2, 3].map(item => (
					<CardSkeleton key={item} />
				))}
			</section>
		);

	return (
		<div className={styles.cardsWrapper}>
			{/* <Text variant="grotesk/32/bold">
				Pricing /{' '}
				<Text variant="grotesk/32/regular" color="grey">
					annually
				</Text>
			</Text> */}
			<div className={styles.tabsWrapper}>
				<Tabs
					value={interval}
					onChange={setInterval}
					items={[
						{ value: IntervalEnum.YEAR, label: 'Annually' },
						{ value: IntervalEnum.MONTH, label: 'Monthly' }
					]}
				/>
				{/* <div className={styles.tabsSave}>
					<Text variant="inter/15/regular" lineHeight={106}>
						10% off
					</Text>
				</div> */}
			</div>
			{user?.billing_plan === BillingPlan.EXPIRED && (
				<div className={styles.expired}>
					<Icon icon="info" className={styles.orange} />
					<Text variant="grotesk/15/regular" component="p" color="dark_green">
						<span>Your free trial has expired. </span>
						To reactivate your account and continue using Prog.AI, please select one of the available
						subscription plans.
					</Text>
				</div>
			)}
			<section className={styles.cards}>
				<GradientBox className={styles.cardGradient} backgroundClassName={styles.cardGradientBefore}>
					<div className={styles.card}>
						<div className={styles.cardTop}>
							<Text variant="grotesk/20/regular" component="h3" className={styles.cardTitle}>
								Trial
							</Text>
							<Text component="p" variant="grotesk/16/regular" color="grey" className={styles.cardPrice}>
								<Text variant="grotesk/32/bold">Free</Text>
								<Text variant="grotesk/24/medium">/</Text>
								<span className={styles.cardPriceAdditional}>14 days</span>
							</Text>
						</div>
						<Text component="p" variant="inter/16/regular" className={styles.cardDescription}>
							Try our powerful search to source up to 10 candidates. Export data into CSV and start
							hiring.
						</Text>
						<ul className={styles.cardPerks}>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									Unlimited search of over <Text variant="inter/16/medium">60M</Text> candidate
									profiles
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									<Text variant="inter/16/medium">AI search</Text> leveraging 16 search filters
									including programming languages, tenure, diversity, language, education, etc.
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									<Text variant="inter/16/semi">{10} candidate credits</Text> (reveal personal
									information and export to CSV)
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									<Text variant="inter/16/medium">Likely-to-Move</Text> probability: a proprietary
									algorithm predicting job change likelihood in candidates
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									<Text variant="inter/16/medium">Chrome Extension:</Text> build lists of candidates
									easily when browsing LinkedIn or GitHub
								</Text>
							</li>
						</ul>
						<Button className={styles.bottomButton} block component="button" disabled={true}>
							{user?.billing_plan === BillingPlan.FREE ? 'Current plan' : 'Expired'}
						</Button>
					</div>
				</GradientBox>
				{plans[interval]
					.sort((a, b) => a.credits - b.credits)
					.map(plan => (
						<GradientBox
							key={plan.price_id}
							className={cn(styles.cardGradient, {
								[styles.cardPro]: plan.billing_plan === BillingPlan.RECRUITER
							})}
							backgroundClassName={styles.cardGradientBefore}>
							<div className={styles.card}>
								<div className={styles.cardTop}>
									<Text variant="grotesk/20/regular" component="h3" className={styles.cardTitle}>
										{plan.billing_plan}
									</Text>
									<Text
										component="p"
										variant="grotesk/16/regular"
										color="grey"
										className={styles.cardPrice}>
										<Text variant="grotesk/32/bold">
											{currencyFormatter.format(plan.amount / 100)}
										</Text>
										<Text variant="grotesk/24/medium">/</Text>
										<span className={styles.cardPriceAdditional}>
											{plan.interval === IntervalEnum.MONTH ? ' monthly' : ' annually'}
										</span>
									</Text>
								</div>
								{plan.billing_plan === BillingPlan.STARTUP ? (
									<Text component="p" variant="inter/16/regular" className={styles.cardDescription}>
										Startup plan includes all of Prog.AI features, such as AI search, 16 search
										filters, including top computer science schools and the most common female
										names, projects, and integrations, along with 100 credits (candidates’ contacts)
										per month.
									</Text>
								) : (
									<Text component="p" variant="inter/16/regular" className={styles.cardDescription}>
										Recruiter plan includes all of Prog.AI features, such as AI search, 16 search
										filters, including top computer science schools and diversity, projects, and
										integrations, along with 2000 credits (candidates’ contacts) per month.
									</Text>
								)}
								<div className={styles.cardPerks}>
									<ul>
										{plan.billing_plan === BillingPlan.STARTUP ? (
											<>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														Unlimited search of over{' '}
														<Text variant="inter/16/medium">60M</Text> candidate profiles
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														<Text variant="inter/16/medium">AI search</Text> leveraging 16
														search filters including programming languages, tenure,
														diversity, language, education, etc.
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														<Text variant="inter/16/medium">
															{plan.credits} candidate credits
														</Text>{' '}
														monthly (reveal personal information and export to CSV)
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														<Text variant="inter/16/medium">Likely-to-Move</Text>{' '}
														probability: a proprietary algorithm predicting job change
														likelihood in candidates
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														<Text variant="inter/16/medium">Chrome Extension:</Text> build
														lists of candidates easily when browsing LinkedIn or GitHub
													</Text>
												</li>
											</>
										) : (
											<>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														Unlimited search of over{' '}
														<Text variant="inter/16/medium">60M</Text> candidate profiles
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														<Text variant="inter/16/medium">AI search</Text> leveraging 16
														search filters including programming languages, tenure,
														diversity, language, education, etc.
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														<Text variant="inter/16/medium">
															{plan.credits} candidate credits
														</Text>{' '}
														monthly (reveal personal information and export to CSV)
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														<Text variant="inter/16/medium">Likely-to-Move</Text>{' '}
														probability: a proprietary algorithm predicting job change
														likelihood in candidates
													</Text>
												</li>
												<li>
													<img src={Check} alt="check icon" />
													<Text variant="inter/16/regular" component="p">
														<Text variant="inter/16/medium">Chrome Extension:</Text> build
														lists of candidates easily when browsing LinkedIn or GitHub
													</Text>
												</li>
											</>
										)}
									</ul>
								</div>
								{plan.billing_plan === BillingPlan.STARTUP ? (
									<CardButton billingPlan={plan.billing_plan} priceId={plan.price_id} />
								) : (
									<CardButton
										className={styles.bottomButton}
										billingPlan={plan.billing_plan}
										priceId={plan.price_id}
									/>
								)}
							</div>
						</GradientBox>
					))}

				<GradientBox className={styles.cardGradient} backgroundClassName={styles.cardGradientBefore}>
					<div className={styles.card}>
						<div className={styles.cardTop}>
							<Text variant="grotesk/20/regular" component="h3" className={styles.cardTitle}>
								Enterprise
							</Text>
							<Text
								component="p"
								variant="grotesk/16/regular"
								color="grey"
								className={cn(styles.cardPrice, styles.cardPriceCustom)}>
								<Text variant="grotesk/32/bold">Custom</Text>
							</Text>
						</div>
						<Text component="p" variant="inter/16/regular" className={styles.cardDescription}>
							Contact our sales to learn more about a custom plan for your team, integration with your ATS
							and other systems, and access to volume discounts.
						</Text>
						<ul className={styles.cardPerks}>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									Unlimited search of over <Text variant="inter/16/medium">60M</Text> candidate
									profiles
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									<Text variant="inter/16/medium">AI search</Text> leveraging 16 search filters
									including programming languages, tenure, diversity, language, education, etc.
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									Pooled credits with volume discount
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									<Text variant="inter/16/medium">Likely-to-Move</Text> probability: a proprietary
									algorithm predicting job change likelihood in candidates
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									<Text variant="inter/16/medium">Chrome Extension:</Text> build lists of candidates
									easily when browsing LinkedIn or GitHub
								</Text>
							</li>
							<li>
								<img src={Check} alt="check icon" />
								<Text variant="inter/16/regular" component="p">
									Custom API/integrations available
								</Text>
							</li>
						</ul>
						<Button
							className={styles.bottomButton}
							block
							component={user?.billing_plan === BillingPlan.ENTERPRISE ? 'button' : 'a'}
							href={`mailto:${PROGAI_EMAIL}`}
							disabled={user?.billing_plan === BillingPlan.ENTERPRISE}>
							{user?.billing_plan === BillingPlan.ENTERPRISE ? 'Current plan' : 'Talk to sales'}
						</Button>
					</div>
				</GradientBox>
			</section>
			<Text component="p" variant="grotesk/16/regular" color="grey" className={styles.contact}>
				To change plan or unsubscribe <a href={`mailto:${PROGAI_EMAIL}`}>Contact&nbsp;us</a>
			</Text>
		</div>
	);
};
