import { Question } from 'features/subscription';

export const questions: Question[] = [
	{
		question: 'What is Prog.AI?',
		answer: 'Prog.AI is a recruitment platform that uses advanced algorithms to evaluate the skills and experience of software developers by analyzing every commit on GitHub. With over 60 million candidates in its database, it makes it easier for companies to find the right fit for their team, by searching for developers based on their coding skills, experience level, and specific technologies they have worked with.'
	},
	{
		question: 'Why use Prog.AI?',
		answer:
			'Here are some of the benefits of using Prog.AI:\n' +
			'\n' +
			'Our AI analyses every commit on open source projects on GitHub as well as broad and unique data points to effectively identify and hire top software engineers across more than 50k skills.\n' +
			'\n' +
			'Large database of candidates: Prog.AI has a database of more than 60 million candidates, making it easy to find the right person for your team.\n' +
			'\n' +
			"Powerful search: Prog.AI's AI search technology makes it easy to find candidates with the specific skills and technologies you're looking for."
	},
	{
		question: 'What is a credit on Prog.AI?',
		answer: 'A credit is an ability to download candidates. For example, if you have 100 credits, you can download a CSV file with 100 candidates and their contact information.'
	}
];
