import { useState } from 'react';

import { Button, ButtonProps } from 'shared/components/ui';

import { CreateProjectModal } from '../CreateProjectModal';

export const CreateProjectButton = ({ onClick, ...otherProps }: ButtonProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		onClick?.();
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Button {...otherProps} onClick={openModal} type="primary" />
			{isModalOpen && <CreateProjectModal onClose={closeModal} />}
		</>
	);
};
