import { FC, useState } from 'react';

import { Button, Icon, openNotification, Text } from 'shared/components/ui';
import { TopIdsCandidates } from 'shared/models';
import { useDeleteProjectCandidatesMutation } from 'services';
import { CenterModal, ModalFooter } from 'shared/components';
import { isPlural } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	disabled?: boolean;
	candidates: TopIdsCandidates & {
		count: number;
	};
	onDelete: () => void;
}

export const DeleteCandidatesButton: FC<Props> = ({ disabled, candidates, onDelete }: Props) => {
	const [removeCandidates] = useDeleteProjectCandidatesMutation();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleRemove = async () => {
		try {
			await removeCandidates(candidates);
			openNotification({
				text: `${candidates.count} ${isPlural(candidates.count) ? 'candidates' : 'candidate'} removed`,
				closable: true
			});
			closeModal();
			onDelete();
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<>
			<Button className={styles.button} type="ghost" disabled={disabled} onClick={openModal}>
				<Icon icon="trash" />
			</Button>
			{isModalOpen && (
				<CenterModal onClose={closeModal} icon="person" iconColor="red">
					<div className={styles.body}>
						<Text variant="inter/20/medium" color="dark_blue">
							Remove {candidates.count} {isPlural(candidates.count) ? 'candidates' : 'candidate'}?
						</Text>
						<Text color="grey">Chosen candidates will be deleted from the project</Text>
					</div>
					<ModalFooter>
						<Button type="ghost" onClick={closeModal}>
							Cancel
						</Button>
						<Button type="danger" onClick={handleRemove}>
							Delete
						</Button>
					</ModalFooter>
				</CenterModal>
			)}
		</>
	);
};
