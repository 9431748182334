import { FC } from 'react';

import { Icon } from 'shared/components/ui';
import { Analytics } from 'features/analytics';
import { ProgAIResults } from 'shared/generated-models';

import styles from './index.module.scss';

interface Props {
	candidate: ProgAIResults['profile'];
}

export const ProfileContacts: FC<Props> = ({ candidate }: Props) => {
	const id = candidate.id;

	let primaryEmail = candidate.primary_email;
	if (!primaryEmail) {
		const emails = candidate.emails;
		if (emails && emails.length > 0) {
			primaryEmail = emails[0];
		}
	}

	const handleGithubClick = () => {
		Analytics.trackGithubClick(id!, candidate.github_url!);
	};

	const handleLinkedinClick = () => {
		Analytics.trackLinkedinClick(id!, candidate.linkedin_url!);
	};

	const handleTwitterClick = () => {
		Analytics.trackTwitterClick(id!, candidate.twitter_url!);
	};

	const handleFacebookClick = () => {
		Analytics.trackFacebookClick(id!, candidate.facebook_url!);
	};

	const handleStackoverflowClick = () => {
		Analytics.trackStackoverflowClick(id!, candidate.stackoverflow_url!);
	};

	const handleHomepageClick = () => {
		Analytics.trackHomepageClick(id!, candidate.homepage_url!);
	};

	const handleEmail = (email: string) => {
		Analytics.trackEmailCopy(id!, email);
	};

	const handlePrimaryEmail = () => {
		handleEmail(primaryEmail!);
	};

	return (
		<div className={styles.contacts}>
			{primaryEmail && (
				<a
					className={styles.iconMail}
					href={`mailto:${primaryEmail}`}
					onClick={handlePrimaryEmail}>
					<Icon icon="mail" size={20} />
				</a>
			)}
			{candidate.github_handle && (
				<a
					onClick={handleGithubClick}
					className={styles.iconGithub}
					href={`https://github.com/${candidate.github_handle}`}
					target="_blank"
					rel="noreferrer">
					<Icon icon="github-logo" size={20} />
				</a>
			)}
			{candidate.linkedin_url && (
				<a
					onClick={handleLinkedinClick}
					className={styles.iconLinkedin}
					href={candidate.linkedin_url}
					target="_blank"
					rel="noreferrer">
					<Icon icon="linkedin-logo" size={20} />
				</a>
			)}
			{candidate.twitter_url && (
				<a
					onClick={handleTwitterClick}
					className={styles.iconTwitter}
					href={candidate.twitter_url}
					target="_blank"
					rel="noreferrer">
					<Icon icon="twitter-logo" size={20} />
				</a>
			)}
			{candidate.facebook_url && (
				<a
					onClick={handleFacebookClick}
					className={styles.iconFacebook}
					href={candidate.facebook_url}
					target="_blank"
					rel="noreferrer">
					<Icon icon="facebook-logo" size={20} />
				</a>
			)}
			{candidate.stackoverflow_url && (
				<a
					onClick={handleStackoverflowClick}
					href={candidate.stackoverflow_url}
					target="_blank"
					rel="noreferrer">
					<Icon icon="stackoverflow-logo" size={20} />
				</a>
			)}
			{candidate.homepage_url && (
				<a
					onClick={handleHomepageClick}
					className={styles.iconWebsite}
					href={candidate.homepage_url}
					target="_blank"
					rel="noreferrer">
					<Icon icon="website" size={20} />
				</a>
			)}
		</div>
	);
};
