import HubspotLogo from 'shared/assets/svg-icons/hubspot-logo.svg';

import { GeneralCard } from '../GeneralCard';

import styles from './index.module.scss';

export const HubspotCard = () => {
	return (
		<GeneralCard
			icon={<img className={styles.logo} src={HubspotLogo} alt="hubspot logo" />}
			title="Hubspot"
			subtitle={<span>Streamline candidate relationships with HubSpot</span>}
		/>
	);
};
