/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SeniorityEnum {
    JUNIOR = 'Junior',
    MIDDLE = 'Middle',
    SENIOR = 'Senior',
    ROCK_STAR = 'Rock Star',
}
