import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Button, Icon } from 'shared/components/ui';
import { ProjectName } from 'features/projects';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
}

export const ProjectHeader: FC<Props> = ({ className, children }: Props) => {
	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1);
	};

	return (
		<div className={cn(className, styles.header)}>
			<div className={styles.left}>
				<Button onClick={handleBack} type="ghost" className={styles.back}>
					<Icon icon="chevron" />
				</Button>
				<ProjectName />
			</div>
			<div className={styles.actions}>{children}</div>
		</div>
	);
};
