import React, { FC } from 'react';
import cn from 'classnames';

import { ProgAiEducation } from 'shared/generated-models';
import { datePeriod } from 'features/candidate/utils';
import { CandidateProfileAvatar } from 'shared/components';
import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	data: ProgAiEducation;
	className?: string;
}

export const Education: FC<Props> = ({ data, className }: Props) => {
	return (
		<div className={cn(styles.education, className)}>
			<CandidateProfileAvatar className={styles.educationAvatar}>🎓</CandidateProfileAvatar>
			<div className={styles.educationInfo}>
				<Text component="p" variant="inter/15/semi">
					{data.campus}
				</Text>
				<Text component="p">{`${data.specialization || ''} ${data.major || ''}`}</Text>
				<Text color="grey">{datePeriod(data.startDate, data.endDate)}</Text>
			</div>
		</div>
	);
};
