import { FC, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Link } from 'react-router-dom';

import { CenterModal, CreditsSummary, ModalFooter } from 'shared/components';
import { Button, Checkbox, Icon, openNotification, Text } from 'shared/components/ui';
import { useGetCurrentUserQuery } from 'services';
import { useIsFree } from 'features/auth';
import { CandidatesWithCount } from 'features/csv-export';
import { isPlural } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
	candidates: CandidatesWithCount;
	handleExport: (isFull: boolean) => void;
	isFree?: boolean;
}

export const ExportGoogleSheetsModal: FC<Props> = ({ onClose, candidates, handleExport, isFree }: Props) => {
	const [isFull, setIsFull] = useState(false);

	const handleCheckbox = (e: CheckboxChangeEvent) => {
		setIsFull(e.target.checked);
	};

	const { data } = useGetCurrentUserQuery();

	const userHasFreePlan = useIsFree();

	const handleSubmit = async () => {
		try {
			await handleExport(isFull);

			openNotification({
				key: 'export-notification',
				text: 'We’re preparing your Google Sheet. It will be opened in a new tab in a few seconds.\nMake sure your browser allows pop-ups.',
				closable: true
			});

			onClose();
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<CenterModal onClose={onClose} icon="export" iconColor="green" title="Export profiles into Google Sheet">
			<div className={styles.body}>
				{data && userHasFreePlan && !isFree && (
					<div className={styles.upgrade}>
						<div>
							<Icon icon="info" />
							<Text variant="grotesk/14/medium" component="p" color="dark_green">
								{`You can export up to ${data.credits} profiles.`}
							</Text>
						</div>
						<Link to="/registration/plan" className={styles.upgradeLink}>
							<Text variant="inter/15/semi">Get more</Text>
						</Link>
					</div>
				)}
				{isFree ? (
					<div className={styles.count}>
						<Icon icon="person" />
						<Text variant="inter/14/medium" color="dark_blue">
							{candidates.count} {isPlural(candidates.count) ? 'candidates' : 'candidate'}
						</Text>
					</div>
				) : (
					<CreditsSummary
						candidates={candidates.count}
						credits={candidates.count}
						tooltip="No credits will be charged for candidates with previously opened
				contacts."
					/>
				)}
				<Checkbox className={styles.checkbox} checked={isFull} onChange={handleCheckbox}>
					<Text variant="inter/14/medium">Include all fields in Google Sheet</Text>
				</Checkbox>
				<div className={styles.inline}>
					<Text color="grey" component="p" className={styles.bullet}></Text>
					<Text color="grey" component="p">
						By default Google Sheet includes basic information about the candidate. You can choose to
						include all information. <br />
						<br />
						After the Google Sheet is created, it will be opened in a new tab.
					</Text>
				</div>
				<div className={styles.inline}>
					<Text color="grey" component="p" className={styles.bullet}></Text>
					<Text color="grey" component="p">
						Connected gmail: <span>{data?.email}</span>
					</Text>
				</div>
			</div>
			<ModalFooter>
				<Button type="ghost" onClick={onClose}>
					Cancel
				</Button>
				{data && (
					<Button type="primary" disabled={!isFree && data.credits < candidates.count} onClick={handleSubmit}>
						Export
					</Button>
				)}
			</ModalFooter>
		</CenterModal>
	);
};
