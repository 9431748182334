import GreenhouseLogo from 'shared/assets/svg-icons/greenhouse-logo.svg';

import { GeneralCard } from '../GeneralCard';

import styles from './index.module.scss';

export const GreenhouseCard = () => {
	return (
		<GeneralCard
			icon={<img className={styles.logo} src={GreenhouseLogo} alt="greenhouse logo" />}
			title="Greenhouse"
			subtitle={<span>Optimize workflows through Greenhouse</span>}
		/>
	);
};
