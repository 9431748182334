import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { Text, TextButton } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	text: string;
	className?: string;
}

export const ReadMoreText: FC<Props> = ({ text, className }: Props) => {
	const [showButton, setShowButton] = useState(false);
	const [open, setOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const handleClick = () => {
		setOpen(open => !open);
	};

	useEffect(() => {
		if (ref && ref.current) {
			if (ref.current.clientWidth < ref.current.scrollWidth) {
				setShowButton(true);
			}
		}
	}, [ref]);

	return (
		<div className={styles.wrapper}>
			<div className={cn(styles.text, { [styles.textHide]: !open })} ref={ref}>
				<Text className={styles.textColor}>{text}</Text>
			</div>
			{showButton && (
				<div className={styles.button}>
					<TextButton className={styles.buttonMedium} onClick={handleClick}>
						{open ? 'Hide' : 'Read more'}
					</TextButton>
				</div>
			)}
		</div>
	);
};
