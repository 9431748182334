import { FC } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Space } from 'antd';

import SourceWhaleLogo from 'shared/assets/svg-icons/source-whale-logo.svg';
import { Alert, CenterModal, ModalFooter } from 'shared/components';
import {
	Button,
	Checkbox,
	FormikCheckboxGroup,
	Icon,
	SkeletonRow,
	Text,
	Radio,
	FormikRadioGroup
} from 'shared/components/ui';
import { useGetCampaignsQuery } from 'services';
import { SourceWhaleCampaignForm, SourceWhaleResult } from 'features/integrations/models';
import { ServiceEnum } from 'shared/generated-models';

import { SourceWhaleAccordion } from '../SourceWhaleAccordion';

import styles from './index.module.scss';

interface Props {
	onSelect: (result: SourceWhaleResult) => void;
	onClose: () => void;
}

export const SelectSourceWhaleCampaignModal: FC<Props> = ({ onSelect, onClose }: Props) => {
	const { data, isFetching } = useGetCampaignsQuery(ServiceEnum.SOURCEWHALE, {
		refetchOnMountOrArgChange: true
	});

	const formik = useFormik<SourceWhaleCampaignForm>({
		initialValues: { campaign: null, projects: [] },
		onSubmit: async values => {
			const result: Partial<SourceWhaleResult> = {};

			if (values.campaign) {
				result.campaign = data?.campaigns.find(item => item.campaignId === values.campaign)!;
			}

			if (values.projects.length > 0) {
				result.projects = data?.projects.filter(item => values.projects.includes(item.projectId))!;
			}

			onSelect(result);
		}
	});

	const isEmpty =
		(!data?.projects || (data?.projects && data?.projects.length === 0)) &&
		(!data?.campaigns || (data?.campaigns && data?.campaigns.length === 0));

	return (
		<CenterModal width={710} onClose={onClose} showClose>
			<div className={styles.header}>
				<div className={styles.logo}>
					<img src={SourceWhaleLogo} alt="SourceWhale logo" />
				</div>
				<Text variant="inter/20/medium" color="dark_blue">
					Export contacts to SourceWhale
				</Text>
			</div>
			{isFetching ? (
				<div>
					<SkeletonRow height={60} /> <br />
					<SkeletonRow height={60} /> <br />
					<SkeletonRow height={60} />
				</div>
			) : (
				<FormikProvider value={formik}>
					<Form>
						<div className={styles.body}>
							{isEmpty ? (
								<Alert icon="info">
									<Text variant="grotesk/14/regular" color="dark_green">
										To add candidates to projects or campaigns you need to go to{' '}
										<a
											href="https://sourcewhale.app/"
											target="_blank"
											rel="noreferrer"
											className={styles.link}>
											SourceWhale
										</a>{' '}
										and create it there first
									</Text>
								</Alert>
							) : null}
							<Text variant="inter/14/regular" color="grey">
								You can export candidates to projects, campaign or both. Or you can skip this step and
								the candidates will be added to a general folder.
							</Text>

							<div className={styles.variants}>
								<SourceWhaleAccordion
									icon={<Icon icon="folder-thin" className={styles.icon} />}
									title="Export to projects"
									subtitle="Add candidates to SourceWhale projects">
									{data?.projects && data?.projects.length > 0 ? (
										<div className={styles.list}>
											<FormikCheckboxGroup className={styles.group} name="projects">
												{data?.projects.map(item => (
													<Checkbox key={item.projectId} value={item.projectId}>
														{item.projectName}
													</Checkbox>
												))}
											</FormikCheckboxGroup>
										</div>
									) : (
										<Alert icon="info">
											<Text variant="grotesk/14/regular" color="dark_green">
												No projects found - go to{' '}
												<a
													href="https://sourcewhale.app/projects"
													target="_blank"
													rel="noreferrer"
													className={styles.link}>
													SourceWhale
												</a>{' '}
												and create a project first
											</Text>
										</Alert>
									)}
								</SourceWhaleAccordion>

								<SourceWhaleAccordion
									icon={<Icon icon="email" className={styles.icon} />}
									title="Export to campaign"
									subtitle="Add candidates to SourceWhale campaigns">
									{data?.campaigns && data?.campaigns.length > 0 ? (
										<div className={styles.list}>
											<FormikRadioGroup className={styles.group} name="campaign">
												<Space direction="vertical">
													{data?.campaigns.map(item => (
														<Radio key={item.campaignId} value={item.campaignId}>
															{item.campaignName}
														</Radio>
													))}
												</Space>
											</FormikRadioGroup>
										</div>
									) : (
										<Alert icon="info">
											<Text variant="grotesk/14/regular" color="dark_green">
												Campaigns not found - go to{' '}
												<a
													href="https://sourcewhale.app/campaigns"
													target="_blank"
													rel="noreferrer"
													className={styles.link}>
													SourceWhale
												</a>{' '}
												and create a campaign first
											</Text>
										</Alert>
									)}
								</SourceWhaleAccordion>
							</div>
						</div>
					</Form>

					{(formik.errors as any).form ? (
						<Text variant="inter/14/regular" color="red">
							{(formik.errors as any).form}
						</Text>
					) : null}

					<ModalFooter>
						<Button type="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button type="primary" onClick={formik.submitForm}>
							Next
						</Button>
					</ModalFooter>
				</FormikProvider>
			)}
		</CenterModal>
	);
};
