import { useEffect, useState } from 'react';

import { TableSelectionMode } from '../models';

export const useTableSelection = <T extends { id: number }>({
	data,
	getKey = (item: T) => item.id,
	pageCount = 20,
	totalCount,
	isFree,
	max
}: {
	data?: Array<T>;
	getKey?: (i: T) => number;
	pageCount?: number;
	totalCount?: number;
	isFree?: boolean;
	max?: number;
}) => {
	const [included, setIncluded] = useState<number[]>([]);
	const [includedItems, setIncludedItems] = useState<T[]>([]);
	const [excluded, setExcluded] = useState<number[]>([]);
	const [count, setCount] = useState(0);
	const [selectedCount, setSelectedCount] = useState(0);
	const total = totalCount ? totalCount : data?.length || 0;

	useEffect(() => {
		if (count === 0) {
			setMode('include');
		}
	}, [count]);

	useEffect(() => {
		data && setIncludedItems(data.filter(item => included.includes(item.id)));
	}, [included]);

	const [mode, setMode] = useState<TableSelectionMode>('include');

	const someSelected = count > 0;

	const isSelected = ({ row, page = 0, idx = 0 }: { row: T; page?: number; idx?: number }): boolean => {
		if (mode === 'include') return included.includes(getKey(row));

		return !(excluded.includes(getKey(row)) || page * pageCount + idx >= selectedCount);
	};

	const filteredList = data || [];
	const isAllSelected =
		(data && data.length > 0 && filteredList.every(row => isSelected({ row }))) || mode === 'exclude';

	const toggle = ({ row, page = 0, idx = 0 }: { row: T; page?: number; idx?: number }) => {
		if (mode === 'include') {
			handleIncludeToggle(row);
		} else {
			if (page * pageCount + idx >= selectedCount) return;
			handleExcludeToggle(row);
		}
	};

	const handleIncludeToggle = (row: T) => {
		if (isSelected({ row })) {
			setIncluded(included => included.filter(i => i !== getKey(row)));
			setCount(count => count - 1);
		} else {
			setIncluded(included => [...included, getKey(row)]);
			setCount(count => count + 1);
		}
	};

	const handleExcludeToggle = (row: T) => {
		if (isSelected({ row })) {
			setExcluded(excluded => [...excluded, getKey(row)]);
			setCount(count => count - 1);
			return;
		}

		setExcluded(excluded => excluded.filter(i => i !== getKey(row)));
		setCount(count => count + 1);
	};

	const selectPage = () => {
		setSelectedCount(0);
		setMode('include');
		setExcluded([]);
		if (data) {
			setIncluded(data.map(row => getKey(row)));
			setCount(data.length);
		}
	};

	const togglePage = () => {
		setSelectedCount(0);
		setMode('include');
		setExcluded([]);
		if (isAllSelected) {
			setIncluded([]);
			setCount(0);
		} else if (data) {
			setIncluded(data.map(row => getKey(row)));
			setCount(data.length);
		}
	};

	const selectAll = () => {
		if (isFree) {
			selectCount(total);
			return;
		}

		if (typeof max === 'number') {
			selectCount(max);
			return;
		}

		selectCount(total);
	};

	const selectCount = (c: number) => {
		setIncluded([]);
		setExcluded([]);
		setMode('exclude');

		if (max) {
			setSelectedCount(c);
			setCount(c);
			return;
		}

		setSelectedCount(Math.min(c, total));
		setCount(Math.min(c, total));
	};

	const clearSelection = () => {
		setSelectedCount(0);
		setIncluded([]);
		setExcluded([]);
		setMode('include');
		setCount(0);
	};

	return {
		selectAll,
		selectPage,
		selectCount,
		someSelected,
		included,
		excluded,
		count,
		setSelected: setIncluded,
		isSelected,
		toggle,
		togglePage,
		isAllSelected,
		clearSelection,
		selectedCount,
		includedItems,
		mode
	};
};
