import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	title: string;
	subtitle: ReactNode;
	icon: ReactNode;
}

export const GeneralCard: FC<Props> = ({ title, subtitle, icon, className }: Props) => {
	return (
		<div className={cn(styles.card, className)}>
			<div className={styles.header}>
				<div className={styles.headerLeft}>
					{icon}
					<div>
						<Text variant="inter/15/semi" component="p" className={styles.headerTitle}>
							{title}
						</Text>
						<Text color="grey" component="p">
							{subtitle}
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
};
