import { useState } from 'react';

import { TextButton, Text, Icon } from 'shared/components/ui';

import styles from './index.module.scss';

const POPULAR_SEARCHES = [
	{
		id: 1,
		title: 'Machine learning engineers',
		body: 'In the United States, with 5+ years of experience',
		link: '/search/results?countries[]=United States&job_titles[]=Machine Learning Engineer&page=0&prog_langs[]=Python,Java,PHP&required_skills[]=artificial-intelligence,python,data-mining,data,pattern-recognition,recommendation-systems,shell-scripts,java,shell,php,perl,scripts,machine-learning&size=100&yo_experience[]=5-10,10%2B'
	},
	{
		id: 2,
		title: 'Devops engineers',
		body: 'In the United States, with 5+ years of experience',
		link: '/search/results?countries[]=United States&job_titles[]=DevOps Engineer,Senior DevOps Engineer&page=0&size=100&yo_experience[]=5-10,10%2B'
	},
	{
		id: 3,
		title: 'React.JS Engineers',
		body: 'In Latin America, with 3+ years of experience',
		link: '/search/results?page=0&required_skills[]=react,reactjs,react-native&size=100&sub_regions[]=Latin America and the Caribbean&yo_experience[]=10%2B,3-5,5-10'
	},
	{
		id: 4,
		title: 'Rust engineers',
		body: 'In Eastern Europe ',
		link: '/search/results?page=0&prog_langs[]=Rust&size=100&sub_regions[]=Eastern Europe&yo_experience[]=10%2B,3-5,5-10,0-3'
	},
	{
		id: 5,
		title: 'Full-stack developers',
		body: 'In San Francisco',
		link: '/search/results?job_titles[]=Full Stack Developer&locations[]=San Francisco%2C California&page=0&size=100&yo_experience[]=10%2B,5-10,3-5,0-3'
	},
	{
		id: 6,
		title: 'Computer vision developers',
		body: 'In Canada',
		link: '/search/results?countries[]=Canada&page=0&required_skills[]=computer-vision&size=100&yo_experience[]=10%2B,5-10,3-5,0-3'
	},
	{
		id: 7,
		title: 'Network engineers',
		body: 'Worldwide',
		link: '/search/results?job_titles[]=Network Engineer&page=0&size=100&yo_experience[]=10%2B,5-10,3-5,0-3'
	},
	{
		id: 8,
		title: 'iOS developers',
		body: 'In the United States',
		link: '/search/results?countries[]=United States&job_titles[]=iOS Developer,Senior iOS Developer&page=0&prog_langs[]=Swift&size=100&yo_experience[]=10%2B,5-10'
	},
	{
		id: 9,
		title: 'Android developers',
		body: 'In the United States',
		link: '/search/results?countries[]=United States&job_titles[]=Android Developer,Senior Android Developer&page=0&prog_langs[]=Kotlin&size=100&yo_experience[]=10%2B,5-10'
	}
];

export const TrendingSearch = () => {
	const [open, setOpen] = useState(false);
	const searchItemsCount = POPULAR_SEARCHES.length;
	const maxItems = open ? searchItemsCount : 5;
	const buttonText = open ? 'See less' : `See all (${searchItemsCount})`;

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				<Icon className={styles.icon} icon={'trending-up'} size={24} />
				<Text variant="inter/16/regular" color="grey">
					Popular searches
				</Text>
			</div>
			<div className={styles.card}>
				{POPULAR_SEARCHES.slice(0, maxItems).map(searchItem => (
					<a
						key={searchItem.id}
						href={searchItem.link}
						target="_blank"
						rel="noreferrer"
						className={styles.link}>
						<div className={styles.cardItem}>
							<Text variant="inter/16/medium" color="grey">
								{searchItem.id}.
							</Text>
							<div className={styles.cardText}>
								<Text variant="inter/16/medium" color="light_blue">
									{searchItem.title}
								</Text>
								<Text variant="inter/16/regular" color="grey" className={styles.text}>
									{searchItem.body}
								</Text>
							</div>
						</div>
					</a>
				))}
				<div className={styles.seeMore}>
					<Text className={styles.seeMoreText} variant="inter/14/regular" color="grey">
						<TextButton onClick={handleClick}>{buttonText}</TextButton>
					</Text>
				</div>
			</div>
			{/* <TrendingSearchSkeleton /> */}
		</div>
	);
};
