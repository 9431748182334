import LeverLogo from 'shared/assets/svg-icons/lever-logo.svg';

import { GeneralCard } from '../GeneralCard';

import styles from './index.module.scss';

export const LeverCard = () => {
	return (
		<GeneralCard
			icon={<img className={styles.logo} src={LeverLogo} alt="lever logo" />}
			title="Lever"
			subtitle={<span>Enhance hiring with Lever's ATS</span>}
		/>
	);
};
