export const AnalyticsEvents = {
	REGISTRATION: 'Sign up',
	LOGIN: 'Log in',
	SEARCH: 'Search',
	OPEN_PROFILE: 'Opened profile',
	EXPORT: 'Export',
	EXPORT_GOOGLE_SHEETS: 'Export Google Sheets',
	SHARE_PROFILE: 'Sharing profile',
	OPEN_SHARED_PROFILE: 'Opening shared profile',
	SUBSCRIPTION: 'Subscription',
	CLICKED_ON_FACEBOOK: 'Clicked on Facebook',
	CLICKED_ON_STACKOVERFLOW: 'Clicked on Stackoverflow',
	CLICKED_ON_HOMEPAGE: 'Clicked on Homepage',
	CLICKED_ON_GITHUB: 'Clicked on GitHub',
	CLICKED_ON_LINKEDIN: 'Clicked on LinkedIn',
	CLICKED_ON_TWITTER: 'Clicked on Twitter',
	COPIED_EMAIL: 'Copied email',
	COPIED_PHONE: 'Copied phone number',
	GET_CONTACT: 'Get contact',
	ADD_TO_PROJECT: 'Add to project',
	PUSH_TO_OUTREACH: 'Push to outreach',
	PARSED_TEXT: 'Parsed text',
	UPGRADE_CLICKED: 'Upgrade clicked',
	TOP_EXPERTS_CLICKED: 'Top Experts clicked',
	CHROME_EXTENSION_CLICKED: 'Chrome Extension clicked'
} as const;
