import React, { FC, useState, WheelEvent } from 'react';
import cn from 'classnames';
import type { ValueType } from '@rc-component/mini-decimal';

import { Button, Checkbox, Dropdown, Icon, InputNumber, Text, TextButton } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	controls: {
		main: {
			togglePage: () => void;
			count: number;
			isAllSelected: boolean;
		};
		extra?: {
			selectPage: () => void;
			selectAll: () => void;
			selectCount: (count: number) => void;
		};
	};
	hint?: string;
}

export const TableSelection: FC<Props> = ({ className, hint, controls }) => {
	const [countValue, setCountValue] = useState(0);
	const [isSelectOpen, setIsSelectOpen] = useState(false);

	const preventNumberInputScroll = (e: WheelEvent<HTMLInputElement>) => {
		// @ts-ignore
		e.target.blur();

		setTimeout(() => {
			// @ts-ignore
			e.target.focus();
		}, 0);
	};

	const { togglePage, isAllSelected, count } = controls.main;

	const handleSelection = () => {
		controls.extra?.selectCount(countValue);
	};

	const handleInputChange = (input: ValueType | null) => {
		const num = input as number;
		if (num >= 0) setCountValue(num);
	};

	return (
		<div className={cn(styles.selectWrapper, className)}>
			<Checkbox onChange={togglePage} checked={isAllSelected} />
			{controls.extra && (
				<Dropdown
					trigger={['click']}
					onOpenChange={setIsSelectOpen}
					dropdownRender={() => (
						<div className={styles.menu}>
							<TextButton className={styles.menuButton} onClick={controls.extra?.selectPage}>
								Select this page
							</TextButton>
							<TextButton className={styles.menuButton} onClick={controls.extra?.selectAll}>
								Select All
							</TextButton>
							<div className={styles.menuSelection}>
								<Text component="p" color="black" className={styles.menuSelect}>
									Select
								</Text>
								<InputNumber
									onWheel={preventNumberInputScroll}
									placeholder="20"
									controls={false}
									value={countValue || undefined}
									onChange={handleInputChange}
									type="number"
									className={styles.menuInput}
								/>
							</div>
							<Button className={styles.menuButtonApply} disabled={!countValue} onClick={handleSelection}>
								Select
							</Button>
						</div>
					)}>
					<div className={styles.selectTriggerWrapper}>
						<Icon
							className={cn(styles.selectTrigger, {
								[styles.selectTriggerOpen]: isSelectOpen
							})}
							icon="arrow-down"
						/>
					</div>
				</Dropdown>
			)}
			<Text color={count === 0 ? 'grey' : 'black'}>{count === 0 ? hint : `Selected ${count}`}</Text>
		</div>
	);
};
