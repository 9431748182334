import { OpenedContactShort, ProgAICandidate } from 'shared/generated-models';

export const formatTitle = (data: OpenedContactShort | ProgAICandidate['profile']): string => {
	if (data.company && data.company === 'Self Employed') return data.company;
	if (!data.company) return data.title || '';
	if (!data.title) return '';
	if (data.title.includes('at')) return data.title;
	if (data.title.includes(data.company)) return data.title;
	return `${data.title} at ${data.company}`;
};
